<template>
    <div :style="getStyle" class="bg-white" id="ratingsCard">
        <vx-card>
            <h2 class="mb-2">
                {{ projectNames.plural }} à réaliser
            </h2>
            <vs-row>
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="12"
                >
                    <vs-table
                        no-data-text="Aucune donnée à afficher"
                        style="width:100%"
                        :data="ratings"
                    >
                        <template slot="thead">
                            <vs-th>Nom</vs-th>
                            <vs-th>Prénom</vs-th>
                            <vs-th>Service</vs-th>
                            <vs-th>Etablissement</vs-th>
                            <vs-th>Réaliser</vs-th>
                        </template>

                        <template slot-scope="{ data }">
                            <vs-tr
                                v-for="(item, i) in ratings"
                                :key="i"
                                :data="item"
                            >
                                <vs-td>
                                    <p>{{ get(item, ":last_name") }}</p>
                                </vs-td>
                                <vs-td>
                                    <p>{{ get(item, ":first_name") }}</p>
                                </vs-td>
                                <vs-td>
                                    {{ get(item, ":services").map(s => s.title).join(", ") }}
                                </vs-td>
                                <vs-td>
                                    <p>
                                        {{ get(item, ":services").map(s => s.establishment.name).join(", ") }}
                                    </p>
                                </vs-td>
                                <vs-td class="action">
                                    <vs-button
                                        v-if="canMulti('update','ratings',item.services.length > 0 ? item.services.map(s => s.id) : null)"
                                        :id="'createRatingEmployee_' + i"
                                        :href="`/rh/gestion/personnes/${item.id}/bilans/creer`"
                                        :v-tooltip="'Créer le '+projectNames.singular"
                                        color="primary"
                                        type="border"
                                        icon-pack="feather"
                                        icon="icon-plus-square"
                                    />
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </vs-col>
            </vs-row>
        </vx-card>
    </div>
</template>
<script>
import EmployeeRatingForm from "@components/views/rh/gestion/employee/rating/RatingForm";
export default {
    components: {
        EmployeeRatingForm
    },
    data() {
        return {
            ratings: ""
        };
    },
    computed: {
        getStyle() {
            return "height: 100%; background-size: contain; background-repeat: no-repeat; border: 2px solid #FCE4EC; border-radius: 5px; background-position: center;";
        }
    },
    created() {
        this.loadRatings();
    },
    methods: {
        showResource(resource) {
            this.resetResource();
            this.setResource(resource);
            this.showPopup = true;
        },
        setResource(data) {
            this.resource = JSON.parse(JSON.stringify(data));
        },
        resetResource() {
            this.resource = {
                title: null,
                description: null
            };
        },
        loadRatings() {
            window
                .axios({
                    method: "get",
                    url: "/api/gestion/employees/norating",
                    params: {
                        perpage: 4,
                        sortBy: { created_at: "desc" },
                        include: ["services.establishment"]
                    }
                })
                .then(response => {
                    this.ratings = response.data.data;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        }
    }
};
</script>

<style lang="scss" scoped>

::v-deep {
    .vx-card {
        height: 100%;
    }
}
</style>
