<template>
    <div>
        <vs-table
            class="border-grey-3"
            :sst="true"
            :search="false"
            no-data-text="Aucun élément disponible"
            style="width:100%"
            :data="exportItems"
        >
            <template slot="thead">
                <vs-th>
                    ID
                </vs-th>
                <vs-th>
                    Titre
                </vs-th>
                <vs-th>
                    Modèle
                </vs-th>
                <vs-th>
                    Visibilité hors admin
                </vs-th>
                <vs-th>
                    Date de création
                </vs-th>
                <vs-th></vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr v-for="(item, i) in data" :key="i" :data="item">
                    <vs-td :data="get(item, ':id')">
                        {{ get(item, ":id") }}
                    </vs-td>
                    <vs-td :data="get(item, ':title')">
                        {{ get(item, ":title") }}
                    </vs-td>
                    <vs-td :data="get(item, ':original_filename')">
                        {{ get(item, ":original_filename") }}
                    </vs-td>
                    <vs-td :data="get(item, ':visibility')">
                        <vs-switch
                            v-model="item.visibility"
                            :disabled="true"
                        />
                    </vs-td>
                    <vs-td :data="get(item, ':created_at')">
                        {{ formatDateTime(get(item, ":created_at")) }}
                    </vs-td>
                    <vs-td class="action">

                        <vs-button
                            v-tooltip="'Modifier'"
                            size="small"
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="icon-edit"
                            @click="$emit('update', item)"
                        />

                        <vs-button
                            v-tooltip="'Supprimer'"
                            size="small"
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="icon-trash"
                            @click.stop="$emit('delete', item)"
                        />
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </div>

</template>

<script>

export default {
    props: {
        exportItems: {
            type: Array,
            required: true
        }
    },
}
</script>