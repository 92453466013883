<template>
    <div class="con-exemple-prompt mb-6 scrollBug">

        <vs-row class="vx-row mb-6">
            <vs-col style="width: calc(37% - 30px)">
                <span>Savoir-faire* :</span>
            </vs-col>

            <vs-col style="width: calc(35% - 30px)">
                <span>Source :</span>
            </vs-col>

            <vs-col style="width: calc(27% - 30px)">
                <span>Filière* :</span>
            </vs-col>

            <vs-col style="min-width: 105px">

            </vs-col>
        </vs-row>

        <vs-row v-for="(resource, i) in resources" :key="i" class="mb-5">
            <vs-col style="width: calc(37% - 30px)">
                <vs-input
                    v-model="resource.title"
                    size="large"
                    name="title"
                    class="w-full"
                />
            </vs-col>

            <vs-col style="width: calc(35% - 30px)">
                <Multiselect
                    v-model="resource.source"
                    :options="sources.data"
                    track-by="id"
                    label="title"
                    group-values="items"
                    group-label="group"
                    name="source"
                    placeholder="Aucune"
                    select-label=""
                    select-group-label=""
                    selected-label=""
                    deselect-label=""
                    deselect-group-label=""
                    :loading="sources.loading"
                    @select="selectSourceHandle($event, resource)"
                >
                    <span slot="noResult">Aucun résultat.</span>
                </Multiselect>
            </vs-col>

            <vs-col style="width: calc(27% - 30px)">
                <Multiselect
                    v-model="resource.sector"
                    :options="sectors.data"
                    track-by="id"
                    label="title"
                    :multiple="false"
                    :group-select="false"
                    placeholder="Recherche ..."
                    select-label=""
                    select-group-label=""
                    selected-label=""
                    deselect-label=""
                    deselect-group-label=""
                    :loading="sectors.loading"
                >
                    <span slot="noResult">Aucun résultat.</span>
                </Multiselect>
            </vs-col>

            <vs-col class="text-center text-nowrap" style="width: 88px">
                <vs-button v-if="resource.edit" radius color="primary" type="border" @click="addNewLine" :disabled_="!canAddNewLine(resource)">
                    <i class="fa fa-plus mx-1"></i>
                </vs-button>

                <vs-button color="primary" type="border" @click="removeLine(i)" :disabled="!canRemoveLine()" class="px-5 py-3">
                    <i class="fa fa-trash"></i>
                </vs-button>
            </vs-col>

        </vs-row>
    </div>

</template>

<script>

import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect
    },
    data() {
        return {
            resources: [],
            sources: {
                data: [],
                loading: false
            },
            sectors: {
                data: [],
                loading: false
            },
        }
    },
    created() {
        this.loadSectors();
        this.loadSources();
        this.addNewLine();
    },
    watch: {
        hasError(value) {
            this.$emit("error", value);
        },
        resources: {
            handler() {
                this.$emit("input", this.resources.map(r => {
                    return {
                        title: r.title,
                        admin_occupation_skill_id: r.source?.id,
                        sector_id: r.sector?.id
                    }
                }));
            },
            deep: true
        },
        'resources.0.sector': function (value) {
            if (value) {
                // Dupliquer la valeur de la 1ère ligne sur les autres, si non renseignées.
                this.resources.forEach(item => {
                    if (!item.sector) {
                        item.sector = value;
                    }
                })
            }
        }
    },
    computed: {

        hasError() {

            let error = false;

            this.resources.forEach(r => {
                if (!r.title || !r.sector) {
                    error = true
                }
            })

            return error;
        },
    },
    methods: {
        addNewLine() {
            this.resources.forEach(r => {
                r.edit = false;
            })

            this.resources.push({
                title: null,
                source: null,
                sector: null,
                edit: true
            });
        },
        canAddNewLine(resource) {
            return !!resource.title && !!resource.sector;
        },
        canRemoveLine() {
            return this.resources.length > 1;
        },
        removeLine(lineNumber) {
            this.resources = this.resources.filter((r, i) => i != lineNumber)
        },
        async loadSources() {
            this.sources.loading = true;
            window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupation-skills/referential",
                    params: { perpage: 9999, include: ['sector'] }
                })
                .then(response => {
                    this.sources.data = this.groupForMultiselect(response.data.data, ['sector.title']);
                    this.sources.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        async loadSectors() {
            return window
                .axios({
                    method: "get",
                    url: "/api/admin/sectors",
                    params: { perpage: 9999, sortBy: {"title": "asc"}, include: [] }
                })
                .then(response => {
                    this.sectors.data = response.data.data;
                    this.sectors.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        selectSourceHandle(event, resource) {
            resource.sector = event.sector;
        }
    }
}
</script>

<style scoped>
.vs-col {
    padding: 0 5px;
}
</style>
