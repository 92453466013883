<template>
    <div>
        <vs-row>
            <vs-col vs-align="left" vs-w="6">
                <vs-button
                    id="saveResource"
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-plus"
                    @click="showFormCreate"
                >
                    Ajouter une nouvelle personne
                </vs-button>

                <DialogPrompt
                    :active.sync="showPopup"
                    title="Ajouter une personne"
                    cancel-text="Annuler"
                    accept-text="Enregistrer"
                    :is-valid="!formError"
                    @cancel="resetResource"
                    @close="resetResource"
                    @accept="saveResource"
                >
                <EmployeeForm
                    v-model="resource"
                    formType="create"
                    @error="setFormError"
                />
                </DialogPrompt>

            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6" />
        </vs-row>

        <vs-table
            :sst="true"
            @search="eventSearchItems"
            @sort="eventSortByCol"
            search
            :no-data-text="loading ? `Chargement de vos données...` : `Aucune donnée à afficher`"
            style="width:100%"
            :data="itemsData"
            >
            <template slot="thead">
                <vs-th><Sort name="last_name" @sort="eventSortByCol">Nom</Sort></vs-th>
                <vs-th><Sort name="first_name" @sort="eventSortByCol">Prénom</Sort></vs-th>
                <vs-th><Sort name="services.establishment,name" @sort="eventSortByCol">Établissements</Sort></vs-th>
                <vs-th><Sort name="services,title" @sort="eventSortByCol">Services</Sort></vs-th>
                <!--vs-th>Établissements</vs-th>
                <vs-th>Services</vs-th-->
                <vs-th>Postes occupés</vs-th>
                <vs-th>Métiers occupés</vs-th>
                <vs-th v-if="canSeeSwitchMobility">Compte portail</vs-th>
                <vs-th></vs-th>
            </template>

            <template>
                <vs-tr v-for="(item, i) in employees" :key="i">
                    <vs-td>
                        <a :href="'/rh/gestion/personnes/' + item.id + '/bilans'">
                            {{ get(item, ':last_name') }}
                        </a>

                    </vs-td>
                    <vs-td>
                        <a :href="'/rh/gestion/personnes/' + item.id + '/bilans'">
                            {{ get(item, ':first_name') }}
                        </a>
                    </vs-td>

                    <vs-td>
                        {{ arrayUnique(get(item, ':active_establishments').map(s => s.name)).join(', ') }}
                        </vs-td>
                    <vs-td>
                        {{ arrayUnique(get(item, ':active_services').map(s => s.title)).join(', ') }}
                        </vs-td>
                    <vs-td>
                        <div v-if="expressEdit.showJobCell != i">
                            <vs-chip v-for="(el, index) in get(item, ':current_jobs')" :key="index">
                                <b>{{ el.title }}</b>
                            </vs-chip>
                            <i class="fas fa-pencil cell-edit opaver" title="Modifier les postes" @click="editJobCellClickHandle(i)"></i>
                        </div>
                        <div v-else>
                            <form>
                                <div class="edit-form">
                                    <Multiselect
                                        v-model="expressEdit.jobValue"
                                        :options="orderedJobs"
                                        :multiple="true"
                                        group-values="jobs"
                                        group-label="establishmentService"
                                        :group-select="true"
                                        placeholder="Recherche ..."
                                        track-by="id"
                                        label="title"
                                        select-label=""
                                        select-group-label=""
                                        selected-label=""
                                        deselect-label=""
                                        deselect-group-label=""
                                        :loading="jobs.loading"
                                    >
                                        <span slot="noResult">Aucun résultat.</span>
                                    </Multiselect>
                                </div>
                                <div class="edit-action text-right mt-2">
                                    <vs-button color="dark" type="border" class="mb-1" @click="resetEditingLine(i)">
                                        <i class="fa fa-times mr-1"></i>
                                        Annuler
                                    </vs-button>

                                    <vs-button color="primary" @click="savePerimeterLine(i)" class="mb-1" :disabled="expressEdit.saving">
                                        <i v-if="!expressEdit.saving" class="far fa-save mr-1"></i>
                                        <i v-else class="fa fa-spinner rotate mr-1"></i>
                                        Enregistrer
                                    </vs-button>
                                </div>
                            </form>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div v-if="expressEdit.showOccupationCell != i">
                            <vs-chip v-for="(el, index) in get(item, ':current_occupations')" :key="index">
                                <b>{{ el.title }}</b>
                            </vs-chip>

                            <i class="fas fa-pencil cell-edit opaver" title="Modifier les métiers" @click="editOccupationCellClickHandle(i)"></i>
                        </div>
                        <div v-else>
                            <form>
                                <div class="edit-form">
                                    <Multiselect
                                        v-model="expressEdit.occupationValue"
                                        :options="orderedOccupations"
                                        :multiple="true"
                                        group-values="occupations"
                                        group-label="type"
                                        :group-select="true"
                                        placeholder="Recherche ..."
                                        track-by="id"
                                        label="title"
                                        select-label=""
                                        select-group-label=""
                                        selected-label=""
                                        deselect-label=""
                                        deselect-group-label=""
                                        :loading="occupations.loading"
                                    >
                                        <span slot="noResult">Aucun résultat.</span>
                                    </Multiselect>
                                </div>
                                <div class="edit-action text-right mt-2">
                                    <vs-button color="dark" type="border" class="mb-1" @click="resetEditingLine(i)">
                                        <i class="fa fa-times mr-1"></i>
                                        Annuler
                                    </vs-button>

                                    <vs-button color="primary" @click="savePerimeterLine(i)" class="mb-1" :disabled="expressEdit.saving">
                                        <i v-if="!expressEdit.saving" class="far fa-save mr-1"></i>
                                        <i v-else class="fa fa-spinner rotate mr-1"></i>
                                        Enregistrer
                                    </vs-button>
                                </div>
                            </form>
                        </div>
                    </vs-td>
                    <vs-td>
                        <div v-if="planAllow('auto-eval-falc-portail')">
                            <vs-switch
                                v-if="canSeeSwitchMobility"
                                v-model="items.data[i].mobility"
                                :disabled="!canMulti('update', 'employees', item.active_services.map(s => s.id))"
                                @click.prevent.stop="eventSwitchMobility(i)"
                            />
                        </div>

                        <div v-else-if="!shouldHideOptions()" style="position: relative; float: left" title="Votre formule n'inclut pas cette fonctionnalité">
                            <vs-switch
                                v-model="items.data[i].mobility"
                                :disabled="true"
                            />
                            <vs-icon class="button-bullet" bg="#FF9B3A" round >
                                <CrownIcon style="width: 16px; height: 16px; padding: 3px"/>
                            </vs-icon>
                        </div>
                    </vs-td>
                    <vs-td class="action">
                        <div class="action-wrapper">
                            <vs-button
                                v-if="can('read', 'employee_ratings')"
                                :id="'ratingEmployee_' + i"
                                :href="'/rh/gestion/personnes/' + item.id + '/bilans'"
                                v-tooltip="'Evaluer'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-check-square"
                            />

                            <vs-button
                                :id="'modifyEmployee_' + i"
                                :href="'/rh/gestion/personnes/' + item.id + '/parcours'"
                                v-tooltip="'Compléter le parcours'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-edit"
                            />

                            <vs-button
                                v-if="isASuperAdmin()"
                                :id="'deleteEmployee_' + i"
                                v-tooltip="'Supprimer'"
                                color="primary"
                                type="border"
                                icon-pack="feather"
                                icon="icon-trash"
                                @click.stop="removeResource(item)"
                            />
                        </div>
                    </vs-td>
                </vs-tr>
            </template>

        </vs-table>

        <vs-row>
            <vs-col class="mt-4" v-if="items.total > 0">
                <vs-pagination :total="Math.ceil(items.total / apiParams.perpage)" v-model="currentPage"></vs-pagination>
            </vs-col>
        </vs-row>
    </div>

</template>

<script>
import objectToFormData from "object-to-formdata";
import EmployeeForm from '@components/views/rh/gestion/employee/EmployeeForm';
import CrownIcon from '@components/svg/CrownIcon';
import Multiselect from "vue-multiselect";

export default {
    name: 'Employees',
    components: {
        EmployeeForm, CrownIcon, Multiselect
    },
    props: {
        api: {
            type: String
        },
        perpage: {
            type: String
        }
    },
    data() {
        return {
            log: console.log,
            config: [],
            currentPage: 1,
            loading: false,
            showPopup: false,
            resource: {
                last_name: null,
                first_name: null,
                birthday: null,
                photo: null,
                services: [],
                occupations: [],
                jobs: []
            },
            formError: true,
            expressEdit: {
                showJobCell: null,
                showOccupationCell: null,
                jobValue: null,
                occupationValue: null,
                saving: false,
            },
            establishments: {
                loading: true,
                data: []
            },
            jobs: {
                loading: true,
                data: []
            },
            occupations: {
                loading: true,
                data: []
            }
        }
    },

    computed: {
        canSeeSwitchMobility() {
            return this.can('update', 'employees')
        },

        employees() {
            return this.items.data.map(el => {
                el.mobilityEditable = window._.intersection(this.authorizedEstablishmentsByLicence('mobilite'), el.active_establishments.map(s => s.id)).length
                return el;
            })
        },
        orderedJobs() {
            let jobsSorted = [];

            this.establishments.data.forEach(e => {
                e.services.forEach(s => {

                    const jobs = this.jobs.data
                                    .filter(j => j.service_id == s.id)
                                    .filter(j => !j.deleted_at)
                                    .sort(function(a, b) {
                                        if (a.title > b.title) return 1;
                                        if (a.title < b.title) return -1;
                                        return 0;
                                    });

                    jobsSorted.push({
                        establishmentService: `${e.name} - ${s.title}`,
                        jobs: jobs
                    });
                });
            });

            return jobsSorted.sort(function(a, b) {
                if (a.establishmentService > b.establishmentService) return 1;
                if (a.establishmentService < b.establishmentService) return -1;
                return 0;
            });
        },
        orderedOccupations() {
            let occupationsTypes = [];

            this.occupations.data.forEach(o => {
                if (!o.deleted_at) {
                    let index = occupationsTypes.findIndex(t => t.type === o.type);
                    if (index == -1) {
                        occupationsTypes.push({
                            type: o.type,
                            occupations: [o]
                        });
                    } else {
                        occupationsTypes[index].occupations.push(o);
                    }
                }
            });

            return occupationsTypes
                .map(type => {
                    type.occupations = type.occupations.sort(function(a, b) {
                        if (a.type > b.type) return 1;
                        if (a.type < b.type) return -1;
                        return 0;
                    });
                    return type;
                })
                .sort(function(a, b) {
                    if (a.type > b.type) return 1;
                    if (a.type < b.type) return -1;
                    return 0;
                });
        },
    },

    watch: {
        loading(value) {
            if (value) {
                this.$vs.loading()
            } else {
                this.$vs.loading.close()
            }
        }
    },

    methods: {
        async loadEstablishments() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/establishments",
                    params: {
                        perpage: 9999,
                        include: ['services']
                    }
                })
                .then(response => {
                    this.establishments.data = response.data.data;
                    this.establishments.loading = false;
                })
                .catch(err => {
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        async loadJobs() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/jobs",
                    params: {
                        perpage: 9999,
                        include: [
                            'service.establishment',
                            'occupation_skills'
                        ],
                        withTrash: 'true',
                    }
                })
                .then(response => {
                    let jobs = response.data.data
                    this.jobs.data = jobs
                    this.jobs.loading = false;
                })
                .catch(err => {
                    this.notifyError(err, `Une erreur est survenue`);
                    console.error(err)
                });
        },

        async loadOccupations() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupations",
                    params: {
                        perpage: 9999,
                        include: [
                            "sector",
                            "occupation_skills",
                            "admin_occupation_skills",
                        ],
                        withTrash: 'true',
                    }
                })
                .then(response => {
                    let occupations = response.data.data
                    this.occupations.data = occupations
                    this.occupations.loading = false;
                })
                .catch(err => {
                    this.notifyError(err, `Une erreur est survenue`);
                    console.error(err)
                });
        },
        eventSwitchMobility: function(id) {
            let mobility = this.items.data[id].mobility;
            this.$swal({
                title: !mobility ? 'Vous êtes sur le point d\'activer le compte Portail pour cette personne. Confirmez-vous ?' : 'L\'accès au Portail sera suspendu. Souhaitez-vous confirmer ?',
                text: "",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Annuler'
            }).then(async result => {
                if (result.value) {
                    mobility = !mobility
                    this.items.data[id].mobility = mobility;
                    let user_id = this.items.data[id].id;

                    window.axios({
                        method: 'post',
                        url: `${this.api}/${user_id}/mobility`,
                        params: {mobility: mobility}
                    })
                    .then(response => {
                        if (mobility) {
                            let e = response.data.mobility_data
                            let url = e.autoLoginUrl
                            //this.$swal(`Identifiant: ${e.username} \nMot de passe: ${e.password}`, '', 'success')
                            this.$swal({
                                //title: `Identifiant: ${e.username} \nMot de passe: ${e.password} \nVous pouvez aller sur <a href=${process.env.MIX_FRONTMOBILITE_URL}/security/connexion target="_blank" >${process.env.MIX_FRONTMOBILITE_URL}</a>`,
                                title: `Identifiant: ${e.username} \nMot de passe: ${e.password} \nVous pouvez aller sur myskilliz.com`,
                                confirmButtonText: 'Connexion automatique',
                                showCloseButton: true,
                            }).then(async result => {
                                if (result.value) {
                                    //window.open(process.env.MIX_FRONTMOBILITE_URL+'/security/connexion?login='+e.username+'&password='+e.password, '_blank')
                                    window.open(url)
                                }
                            });
                        }
                    })
                    .catch(err => {
                        this.items.data[id].mobility = !mobility
                        this.notifyError(err, 'Erreur !', true)
                    })
                }

            })
        },

        showFormCreate() {
            this.resetResource();
            this.showPopup = true
        },

        saveResource() {

            let services = {};
            this.resource.services.forEach((item, i) => {
                services[i] = {id: item.id, etp: item.etp};
            });

            let establishments = {};
            this.resource.establishments.forEach((item, i) => {
                establishments[i] = {id: item.id, start_date: item.start_date}
            });

            let data                 = window._.cloneDeep(this.resource);
                data.occupations     = data.occupations ? data.occupations.map(el => el.id) : null;
                data.jobs            = data.jobs ? data.jobs.map(el => el.id) : null;
                data.services = services;
                data.establishments  = establishments;
                data.occupation_skills_rating_only = data.occupation_skills_rating_only ? 1 : 0;
                data.occupation_skills_self_rating = data.occupation_skills_self_rating ? 1 : 0;

            if (data.id) {
                this.apiUpdate(data, data.id, () => this.loadItems());
            } else {
                window.axios({
                    method: 'post',
                    url: `${this.api}`,
                    data: objectToFormData(data),
                    headers: {'Content-Type': 'multipart/form-data'}
                })
                .then(response => {

                    if (response.status === 200 && response.data != '') {
                        this.notifySuccess('Succès', 'Élement modifié avec succès !')
                        this.loadItems();
                    } else {
                        this.notifyError('Erreur', `Une erreur est survenue lors de la création`)
                    }

                })
                .catch((err, data) => {
                    this.notifyErrorValidation(err)
                    this.showPopup = true

                })
            }
        },

        removeResource(resource) {
            this.apiDelete(resource.id, () => this.loadItems());
        },

        setResource(data) {
            this.resource = JSON.parse(JSON.stringify(data));
        },

        resetResource() {
            this.resource = {
                last_name: null,
                first_name: null,
                birthday: null,
                photo: null,
                services: [],
                occupations: [],
                jobs: [],
                occupation_skills_rating_only: null,
                occupation_skills_self_rating: null
            }
        },

        setFormError (value) {
            this.formError = value;
        },

        editJobCellClickHandle(line) {
            this.expressEdit.showJobCell = line;
            this.expressEdit.jobValue = this.items.data[line].current_jobs;
            this.expressEdit.occupationValue = this.items.data[line].current_occupations;
            this.expressEdit.showOccupationCell = null;
        },
        
        editOccupationCellClickHandle(line) {
            this.expressEdit.showOccupationCell = line;
            this.expressEdit.jobValue = this.items.data[line].current_jobs;
            this.expressEdit.occupationValue = this.items.data[line].current_occupations;
            this.expressEdit.showJobCell = null;
        },

        resetEditingLine() {
            this.expressEdit.showOccupationCell = null;
            this.expressEdit.showJobCell = null;
            this.expressEdit.jobValue = null;
            this.expressEdit.occupationValue = null;
        },

        savePerimeterLine(line) {
            this.expressEdit.saving = true;

            let employee = _.cloneDeep(this.items.data[line]);
            employee.jobs = this.expressEdit.jobValue.map(el => el.id);
            employee.occupations = this.expressEdit.occupationValue.map(el => el.id);

            let establishments = {};
            employee.active_establishments.forEach((item, i) => {
                establishments[i] = {
                    id: item.id,
                    start_date: item.pivot?.start_date,
                    pivot_id: item.pivot?.id
                }
            });

            let services = {};
            employee.active_services.forEach((item, i) => {
                services[i] = {
                    id: item.id,
                    etp: item.pivot?.etp,
                    pivot_id: item.pivot?.id
                };
            });

            employee.services = services;
            employee.establishments = establishments;

            delete employee.current_jobs;
            delete employee.current_occupations;
            delete employee.active_establishments;
            delete employee.active_services;

            window.axios({
                method: 'patch',
                url: '/api/gestion/employees/' + employee.id,
                data: employee
            })
            .then(response => {
                this.expressEdit.saving = false;

                this.items.data[line].current_jobs = this.expressEdit.jobValue;
                this.items.data[line].current_occupations = this.expressEdit.occupationValue;

                this.resetEditingLine();

                this.notifySuccess('Succès', 'Le parcours a été mis à jour !')
            })
            .catch((err) => {
                this.notifyErrorValidation(err)
            })

        }
    },
    created() {
        this.loadEstablishments();
        this.loadJobs();
        this.loadOccupations();
        
        this.apiParams.include = [
            //'services.establishment',
            'active_establishments',
            'active_services.establishment',
            'currentJobs.occupation_skills',
            'currentJobs.service',
            'currentOccupations'
        ];
        this.loadItems();
    }
}
</script>
<style lang="css">
.swal2-title {
    display:block !important;
}
.swal2-popup {
    width: 35em !important;
}

.edit-form {
    min-width: 230px;
}

</style>

<style scoped>
/* Bug sur le multiselect sans cette règle */
/* .vs-table--tbody-table .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover,
.vs-table-primary .is-selected {
    transform: initial !important;
} */

/* Bug sur le multiselect déroulé sans cette règle */
.con-vs-chip {
    float: none;
    display: inline-flex;
}
</style>