var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-authorizations-container vx-row mb-6" },
    [
      _c(
        "div",
        { staticClass: "vx-col w-full ml-4" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.reduced,
                  expression: "!reduced"
                }
              ],
              staticClass: "vx-row mb-6"
            },
            [
              _vm.evaluator
                ? _c("b", [_vm._v("Périmètre de l'évaluateur externe")])
                : _c("b", [
                    _vm._v(
                      "Personne(s) additionnelle(s) au périmètre de l'évaluateur tiers"
                    )
                  ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "vx-row mb-6" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "vx-col sm:w-2/3 w-full px-0" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { name: "userEmail", type: "email" },
                  model: {
                    value: _vm.value.eval_source,
                    callback: function($$v) {
                      _vm.$set(_vm.value, "eval_source", $$v)
                    },
                    expression: "value.eval_source"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.reduced,
                  expression: "!reduced"
                }
              ],
              staticClass: "vx-row mb-6"
            },
            [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-1/3 w-full" },
                [
                  (_vm.evaluator &&
                    _vm.groupedEmployeesByEstablishmentsForEvaluators.length >
                      0) ||
                  (!_vm.evaluator &&
                    _vm.groupedEmployeesByEstablishmentsForNoNEvaluators
                      .length > 0)
                    ? _c(
                        "multiselect",
                        {
                          attrs: {
                            options: _vm.evaluator
                              ? _vm.groupedEmployeesByEstablishmentsForEvaluators
                              : _vm.groupedEmployeesByEstablishmentsForNoNEvaluators,
                            multiple: true,
                            "group-values": "employees",
                            "group-label": "establishment",
                            "group-select": true,
                            placeholder: "Recherche ...",
                            "track-by": "id",
                            label: "name",
                            "select-label": "",
                            "select-group-label": "L'établissement",
                            "selected-label": "",
                            "deselect-label": "",
                            "deselect-group-label": ""
                          },
                          model: {
                            value: _vm.value.employees,
                            callback: function($$v) {
                              _vm.$set(_vm.value, "employees", $$v)
                            },
                            expression: "value.employees"
                          }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "noResult" }, slot: "noResult" },
                            [_vm._v("Aucun résultat.")]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.value.employees, function(employee, index) {
            return _c("div", { key: index, staticClass: "vx-row mb-6" }, [
              _c("div", { staticClass: "vx-col sm:w-3/12 w-full" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.reduced,
                        expression: "!reduced"
                      }
                    ]
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "(" + _vm._s(employee.id) + ") " + _vm._s(employee.name)
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("label", [_vm._v("Évaluation demandée*")]),
                    _vm._v(" "),
                    _c("multiselect", {
                      attrs: {
                        options: _vm.evalTypes,
                        multiple: true,
                        name: "eval_types",
                        "track-by": "slug",
                        label: "title",
                        placeholder: "Recherche ...",
                        "select-label": "",
                        "selected-label": "",
                        "deselect-label": ""
                      },
                      model: {
                        value: employee.eval_types,
                        callback: function($$v) {
                          _vm.$set(employee, "eval_types", $$v)
                        },
                        expression: "employee.eval_types"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              !_vm.reduced || _vm.reducedType === "experience"
                ? _c(
                    "div",
                    { staticClass: "vx-col sm:w-4/12 w-full" },
                    [
                      _c("label", [_vm._v("Poste(s)")]),
                      _vm._v(" "),
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            name: "employee." + employee.id + ".jobs",
                            options: employee.jobs || [],
                            multiple: true,
                            placeholder: "Postes à considérer ...",
                            "track-by": "id",
                            label: "title",
                            "select-label": "",
                            "selected-label": "",
                            "deselect-label": ""
                          },
                          model: {
                            value: employee.jobsToEvaluate,
                            callback: function($$v) {
                              _vm.$set(employee, "jobsToEvaluate", $$v)
                            },
                            expression: "employee.jobsToEvaluate"
                          }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "noResult" }, slot: "noResult" },
                            [_vm._v("Aucun résultat.")]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.reduced || _vm.reducedType === "occupation"
                ? _c(
                    "div",
                    { staticClass: "vx-col sm:w-4/12 w-full" },
                    [
                      _c("label", [_vm._v("Métier(s)")]),
                      _vm._v(" "),
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            name: "employee." + employee.id + ".occupations",
                            options: employee.occupations || [],
                            multiple: true,
                            placeholder: "Métiers à considérer ...",
                            "track-by": "id",
                            label: "title",
                            "select-label": "",
                            "selected-label": "",
                            "deselect-label": ""
                          },
                          model: {
                            value: employee.occupationsToEvaluate,
                            callback: function($$v) {
                              _vm.$set(employee, "occupationsToEvaluate", $$v)
                            },
                            expression: "employee.occupationsToEvaluate"
                          }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "noResult" }, slot: "noResult" },
                            [_vm._v("Aucun résultat.")]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-2/12 w-full" },
                [
                  _c("label", [_vm._v("Compétences")]),
                  _vm._v(" "),
                  _c("vs-switch", {
                    attrs: { name: "eval_skills" },
                    model: {
                      value: employee.eval_skills,
                      callback: function($$v) {
                        _vm.$set(employee, "eval_skills", $$v)
                      },
                      expression: "employee.eval_skills"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vx-col sm:w-3/12 w-full" },
                [
                  _c("label", [_vm._v("Questionnaire")]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "multiselect",
                    {
                      attrs: {
                        name: "questionnaire",
                        options: _vm.questionnaires.data,
                        placeholder: "Choisir...",
                        "track-by": "id",
                        label: "name",
                        "select-label": "",
                        "selected-label": "",
                        "deselect-label": "",
                        loading: _vm.questionnaires.loading
                      },
                      model: {
                        value: employee.questionnaire,
                        callback: function($$v) {
                          _vm.$set(employee, "questionnaire", $$v)
                        },
                        expression: "employee.questionnaire"
                      }
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "noResult" }, slot: "noResult" },
                        [_vm._v("Aucun résultat.")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { attrs: { slot: "noOptions" }, slot: "noOptions" },
                        [_vm._v("La liste est vide.")]
                      )
                    ]
                  )
                ],
                1
              )
            ])
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Source de l'évaluation :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vx-col sm:w-1/3 w-full" }, [
      _c("span", [_vm._v("Personnes :")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }