<template>
    <div>
        <LimitatedPlan v-if="!planAllow('indicateurs-activite')" class="mb-10"/>

        <div class="vx-row">
            <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                <statistics-card-line
                    icon="AwardIcon"
                    icon-right
                    :statistic="skills.length"
                    statistic-title="Référentiel Compétences"
                    custom-color="#FF3399"
                />
            </div>

            <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                <statistics-card-line
                    icon="ApertureIcon"
                    icon-right
                    :statistic="adminoccupationskills.length"
                    statistic-title="Référentiel Savoir-faire"
                    custom-color="#FF3399"
                />
            </div>

            <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                <statistics-card-line
                    icon="TargetIcon"
                    icon-right
                    :statistic="occupations.length"
                    statistic-title="Référentiel Métiers"
                    custom-color="#FF3399"
                />
            </div>
        </div>

        <div class="vx-row mt-10">
            <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                <statistics-card-line
                    icon="ApertureIcon"
                    icon-right
                    :statistic="occupationskills.length"
                    statistic-title="Savoir-faire"
                    custom-color="#048B9A"
                />
            </div>

            <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                <statistics-card-line
                    icon="ClipboardIcon"
                    icon-right
                    :statistic="jobs.length"
                    statistic-title="Postes"
                    color="success"
                />
            </div>

            <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" />
        </div>

        <div class="vx-row mt-10">
            <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                <statistics-card-line
                    icon="UsersIcon"
                    icon-right
                    :statistic="employees.length"
                    statistic-title="Personnes"
                    custom-color="#5A5E6B"
                />
            </div>

            <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                <statistics-card-line
                    icon="LayersIcon"
                    icon-right
                    :statistic="planAllow('indicateurs-activite') ? services.length : 0"
                    statistic-title="Services"
                    color="warning"
                />
            </div>

            <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                <statistics-card-line
                    icon="GridIcon"
                    icon-right
                    :statistic="establishments.length"
                    statistic-title="Établissements"
                    color="danger"
                />
            </div>
        </div>

        <div class="vx-row mt-10">
            <vx-card>
                <h3>Indicateurs d'activité</h3>
                <div class="mt-10">
                    <p><b>Périmètre:</b></p>
                    <div class="vx-row">
                        <div class="vx-col sm:w-1/2 w-full">
                            <multiselect
                                v-model="establishmentsFilter"
                                name="establishments"
                                :options="establishments"
                                :multiple="true"
                                placeholder="Recherche ..."
                                track-by="id"
                                label="name"
                                select-label=""
                                selected-label=""
                                deselect-label=""
                                :loading="establishmentsLoading"
                                :disabled="!planAllow('indicateurs-activite')"
                            >
                                <span slot="noResult">Aucun résultat.</span>
                            </multiselect>
                        </div>
                        <div class="vx-col sm:w-1/4 w-full">
                            <div class="vx-row">
                                <div class="vx-col sm:w-2/12 w-full pt-2">
                                    <span>Du :</span>
                                </div>
                                <div class="vx-col sm:w-10/12 w-full">
                                    <vs-input
                                        type="date"
                                        v-model="startDateFilter"
                                        name="start_date"
                                        class="w-full"
                                        :disabled="!planAllow('indicateurs-activite')"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="vx-col sm:w-1/4 w-full">
                            <div class="vx-row">
                                <div class="vx-col sm:w-2/12 w-full pt-2">
                                    <span>au :</span>
                                </div>
                                <div class="vx-col sm:w-10/12 w-full">
                                    <vs-input
                                        type="date"
                                        v-model="endDateFilter"
                                        name="start_date"
                                        class="w-full"
                                        :disabled="!planAllow('indicateurs-activite')"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="vx-row mt-5">
                        <div class="vx-col sm:w-1/2 w-full">
                            <div class="vx-row">
                                <div class="vx-col sm:w-1/12 w-full">
                                    <vs-switch v-model="includeEmployees" :disabled="!planAllow('indicateurs-activite')"/>
                                </div>
                                <div class="vx-col sm:w-1/4 w-full">
                                    Personnes
                                </div>
                                <div class="vx-col sm:w-1/12 w-full">
                                    <vs-switch v-model="includeNotInStaff" :disabled="!planAllow('indicateurs-activite')"/>
                                </div>
                                <div class="vx-col sm:w-1/4 w-full">
                                    Stagiaires / Hors effectif
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-10">
                    <div class="vx-row">
                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="NbEmployeesInPerimeterAndDate"
                                statistic-title="Nombre total de personnes sur la période"
                                custom-color="#972F5F"
                            />
                        </div>

                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="WithJobReceptionInEA"
                                statistic-title="Nombre de personnes cumulant travail dans un établissement et en entreprise adaptée"
                                custom-color="#972F5F"
                            />
                        </div>

                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="WithJobReceptionInClassicalStructure"
                                statistic-title="Nombre de personnes cumulant travail dans un établissement et en milieu ordinaire"
                                custom-color="#972F5F"
                            />
                        </div>
                    </div>
                </div>

                <div class="mt-10">
                    <div class="vx-row">
                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="WithStageHML"
                                statistic-title="Nombre de personnes ayant effectué une activité hors les murs"
                                custom-color="#972F5F"
                            />
                        </div>

                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="WithStageMAD"
                                statistic-title="Nombre de personnes ayant effectué un MAD (Mise à Disposition)"
                                custom-color="#972F5F"
                            />
                        </div>

                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="WithStagePMSMP"
                                statistic-title="Nombre de personnes ayant effectué une PMSMP (Période de Mise à Situation en Milieu Professionnel)"
                                custom-color="#972F5F"
                            />
                        </div>
                    </div>
                </div>

                <div class="mt-10">
                    <div class="vx-row">

                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <ms-tooltip :text="TextTrainingFranceTravail" position="top">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="WithTrainingFranceTravail"
                                statistic-title="Nombre de personnes inscrites à France Travail"
                                custom-color="#972F5F"
                            />
                            </ms-tooltip>
                        </div>

                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="'-'"
                                statistic-title="Durée moyenne d'une MAD"
                                custom-color="#972F5F"
                            />
                        </div>

                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="'-'"
                                statistic-title="Durée moyenne d'une PMSMP"
                                custom-color="#972F5F"
                            />
                        </div>
                    </div>
                </div>

                <div class="mt-10">
                    <div class="vx-row">
                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="LeftForOrdinary"
                                statistic-title="Nombre de personnes parties pour le milieu ordinaire"
                                custom-color="#972F5F"
                            />
                        </div>
                    </div>
                </div>

                <div class="mt-10">
                    <div class="vx-row">
                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="LeftForOrdinaryCDD"
                                statistic-title="Nombre de personnes parties pour le milieu ordinaire en CDD"
                                custom-color="#972F5F"
                            />
                        </div>

                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="LeftForOrdinaryCDI"
                                statistic-title="Nombre de personnes parties pour le milieu ordinaire en CDI"
                                custom-color="#972F5F"
                            />
                        </div>

                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="LeftForOrdinaryInterim"
                                statistic-title="Nombre de personnes parties pour le milieu ordinaire en intérim"
                                custom-color="#972F5F"
                            />
                        </div>
                    </div>
                </div>

                <div class="mt-10">
                    <div class="vx-row">
                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="LeftForPrivate"
                                statistic-title="Nombre de personnes parties pour un employeur privé"
                                custom-color="#972F5F"
                            />
                        </div>
                    </div>
                </div>

                <div class="mt-10">
                    <div class="vx-row">
                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="LeftForPrivateCDD"
                                statistic-title="Nombre de personnes parties pour un employeur privé en CDD"
                                custom-color="#972F5F"
                            />
                        </div>

                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="LeftForPrivateCDI"
                                statistic-title="Nombre de personnes parties pour un employeur privé en CDI"
                                custom-color="#972F5F"
                            />
                        </div>

                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="LeftForPrivateInterim"
                                statistic-title="Nombre de personnes parties pour un employeur privé en intérim"
                                custom-color="#972F5F"
                            />
                        </div>
                    </div>
                </div>

                <div class="mt-10">
                    <div class="vx-row">
                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="LeftForPublic"
                                statistic-title="Nombre de personnes parties pour un employeur public"
                                custom-color="#972F5F"
                            />
                        </div>
                    </div>
                </div>

                <div class="mt-10">
                    <div class="vx-row">
                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="LeftForPublicCDD"
                                statistic-title="Nombre de personnes parties pour un employeur public en CDD"
                                custom-color="#972F5F"
                            />
                        </div>

                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="LeftForPublicCDI"
                                statistic-title="Nombre de personnes parties pour un employeur public en CDI"
                                custom-color="#972F5F"
                            />
                        </div>

                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="LeftForPublicInterim"
                                statistic-title="Nombre de personnes parties pour un employeur public en intérim"
                                custom-color="#972F5F"
                            />
                        </div>
                    </div>
                </div>

                <div class="mt-10">
                    <div class="vx-row">
                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="LeftForAdapted"
                                statistic-title="Nombre de personnes parties pour une entreprise adaptée"
                                custom-color="#972F5F"
                            />
                        </div>
                    </div>
                </div>

                <div class="mt-10">
                    <div class="vx-row">
                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="LeftForAdaptedCDD"
                                statistic-title="Nombre de personnes parties pour une entreprise adaptée en CDD"
                                custom-color="#972F5F"
                            />
                        </div>

                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="LeftForAdaptedCDI"
                                statistic-title="Nombre de personnes parties pour une entreprise adaptée en CDI"
                                custom-color="#972F5F"
                            />
                        </div>

                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="LeftForAdaptedInterim"
                                statistic-title="Nombre de personnes parties pour une entreprise adaptée en intérim"
                                custom-color="#972F5F"
                            />
                        </div>
                    </div>
                </div>

                <div class="mt-10">
                    <div class="vx-row">
                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="'-'"
                                statistic-title="Nombre de personnes ayant bénéficié d'une formation prise en charge par l'OPCO"
                                custom-color="#972F5F"
                            />
                        </div>

                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="'-'"
                                statistic-title="Nombre de personnes ayant bénéficié d'une formation au titre de leur CPF"
                                custom-color="#972F5F"
                            />
                        </div>

                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="'-'"
                                statistic-title="Nombre de personnes ayant bénéficié d'une formation en interne"
                                custom-color="#972F5F"
                            />
                        </div>
                    </div>
                </div>

                <div class="mt-10">
                    <div class="vx-row">
                        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
                            <statistics-card-line
                                :div="true"
                                icon-right
                                :statistic="WithTrainingAuto"
                                statistic-title="Nombre de personnes ayant bénéficié d'une sensibilisation à l'autodétermination"
                                custom-color="#972F5F"
                            />
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import StatisticsCardLine from "@/components/theme/StatisticsCardLine.vue";
import Multiselect from "vue-multiselect";
import {format} from "date-fns";
import LimitatedPlan from '@components/divers/LimitatedPlan';

export default {
    components: {
        Multiselect,
        StatisticsCardLine,
        LimitatedPlan
    },
    data() {
        return {
            log: console.log,
            skills: [],
            skillsLoading: false,
            adminoccupationskills: [],
            adminoccupationskillsLoading: false,
            occupationskills: [],
            occupationskillsLoading: false,
            jobs: [],
            jobsLoading: false,
            occupations: [],
            occupationsLoading: false,
            employees: [],
            employeesLoading: false,
            establishments: [],
            establishmentsLoading: false,
            establishmentsFilter: [],
            startDateFilter: null,
            endDateFilter: null,
            includeEmployees: true,
            includeNotInStaff: false,
        };
    },

    mounted() {
        let today = new Date()
        let newDate = new Date(
            today.getFullYear()-1,
            today.getMonth(),
            today.getDate()+1
        )
        this.startDateFilter = format(newDate, "yyyy-MM-dd")
        this.endDateFilter = format(today, "yyyy-MM-dd")
    },

    computed: {
        EmployeesInPerimeter() {
            let serviceIDs = []
            this.establishmentsFilter.forEach(establishment => {
                serviceIDs = serviceIDs.concat(establishment.services.map(s => s.id))
            })

            return this.employees.filter(employee => {
                if (this.includeNotInStaff && !this.includeEmployees && !employee.not_in_staff) return false
                if (this.includeEmployees && !this.includeNotInStaff && employee.not_in_staff) return false

                return !!serviceIDs.filter(
                    sid =>
                        employee.active_services.map(s => s.id).includes(sid)
                ).length
            })
        },
        EmployeesInPerimeterAndDate() {
            let serviceIDs = []
            this.establishmentsFilter.forEach(establishment => {
                serviceIDs = serviceIDs.concat(establishment.services.map(s => s.id))
            })

            return this.employees.filter(employee => {
                if (this.includeNotInStaff && !this.includeEmployees && !employee.not_in_staff) return false
                if (this.includeEmployees && !this.includeNotInStaff && employee.not_in_staff) return false

                return !!serviceIDs.filter(
                    sid =>
                        employee.active_services.filter(
                            as =>
                                (!as.pivot.start_date || new Date(as.pivot.start_date).getTime() <= new Date(this.endDateFilter).getTime())
                                && (!as.pivot.end_date || new Date(as.pivot.end_date).getTime() >= new Date(this.startDateFilter).getTime())
                        ).map(s => s.id).includes(sid)
                ).length
            })
        },
        NbEmployeesInPerimeterAndDate() {
            return this.EmployeesInPerimeterAndDate?.length || 0
        },

        WithStageHML() {
            return this.EmployeesInPerimeter.filter(
                e => e.experiences.length && e.experiences.filter(
                    s => s.experience_type.slug == 'HLM'
                        && (!s.start_date || new Date(s.start_date).getTime() >= new Date(this.startDateFilter).getTime())
                )?.length
            )?.length || 0
        },
        WithStageMAD() {
            return this.EmployeesInPerimeter.filter(
                e => e.experiences.length && e.experiences.filter(
                    s => s.experience_type.slug == 'MAD'
                        && (!s.start_date || new Date(s.start_date).getTime() >= new Date(this.startDateFilter).getTime())
                )?.length
            )?.length || 0
        },
        WithStagePMSMP() {
            return this.EmployeesInPerimeter.filter(
                e => e.experiences.length && e.experiences.filter(
                    s => s.experience_type.slug == 'PMSMP'
                        && (!s.start_date || new Date(s.start_date).getTime() >= new Date(this.startDateFilter).getTime())
                )?.length
            )?.length || 0
        },
        WithTrainingAuto() {
            return this.EmployeesInPerimeter.filter(
                e => e.experiences.length && e.experiences.filter(
                    s => s.experience_type.slug == 'SAD'
                        && (!s.start_date || new Date(s.start_date).getTime() >= new Date(this.startDateFilter).getTime())
                )?.length
            )?.length || 0
        },

        WithTrainingFranceTravail() {
            return this.EmployeesInPerimeter.filter(
                e => e.experiences.length && e.experiences.filter(
                    s => s.experience_type.slug == 'IFT'
                        && (!s.start_date || new Date(s.start_date).getTime() <= new Date(this.endDateFilter).getTime())
                )?.length
            )?.length || 0
        },
        TextTrainingFranceTravail() {
            let text = '<P>'
            text+='Nb de personnes du périmètre ayant un suivi d’accompagnement de type “Inscription France Travail” <br>dont la date est antérieure à la date de fin de la période sélectionnée</li>'
            text+='<br><br>Exemple :'
            text+=' <ul>'
            text+='  <li>Date de suivi = 12/07/2020, Période du 01/01/2023 au 31/12/2023 => On compte</li>'
            text+='  <li>Date de suivi = 12/03/2024, Période du 01/01/2023 au 31/12/2023 => On ne compte pas</li>'
            text+=' </ul>'
            text+='</P>'

            return text
        },

        WithJobReceptionInEA() {
            return this.EmployeesInPerimeter.filter(
                e => e.experiences.length && e.experiences.filter(
                    s => s.reception_type = 'EA'
                        && s.experience_type.slug == 'PRES'
                        && new Date(s.start_date).getTime() >= new Date(this.startDateFilter).getTime()
                        && new Date(s.start_date).getTime() <= new Date(this.endDateFilter).getTime()
                )?.length
            )?.length || 0
        },
        WithJobReceptionInClassicalStructure() {
            return this.EmployeesInPerimeter.filter(
                e => e.experiences.length && e.experiences.filter(
                    s => s.reception_type = 'Milieu ordinaire'
                        && s.experience_type.slug == 'PRES'
                        && new Date(s.start_date).getTime() >= new Date(this.startDateFilter).getTime()
                        && new Date(s.start_date).getTime() <= new Date(this.endDateFilter).getTime()
                )?.length
            )?.length || 0
        },

        LeftForOrdinary() {
            return this.EmployeesInPerimeter.filter(
                e => !!e.has_left_for_ordinary
            )?.length || 0
        },
        LeftForOrdinaryCDD() {
            return this.EmployeesInPerimeter.filter(
                e => !!e.has_left_for_ordinary_cdd
            )?.length || 0
        },
        LeftForOrdinaryCDI() {
            return this.EmployeesInPerimeter.filter(
                e => !!e.has_left_for_ordinary_cdi
            )?.length || 0
        },
        LeftForOrdinaryInterim() {
            return this.EmployeesInPerimeter.filter(
                e => !!e.has_left_for_ordinary_interim
            )?.length || 0
        },

        LeftForPrivate() {
            return this.EmployeesInPerimeter.filter(
                e => !!e.has_left_for_private
            )?.length || 0
        },
        LeftForPrivateCDD() {
            return this.EmployeesInPerimeter.filter(
                e => !!e.has_left_for_private_cdd
            )?.length || 0
        },
        LeftForPrivateCDI() {
            return this.EmployeesInPerimeter.filter(
                e => !!e.has_left_for_private_cdi
            )?.length || 0
        },
        LeftForPrivateInterim() {
            return this.EmployeesInPerimeter.filter(
                e => !!e.has_left_for_private_interim
            )?.length || 0
        },

        LeftForPublic() {
            return this.EmployeesInPerimeter.filter(
                e => !!e.has_left_for_public
            )?.length || 0
        },
        LeftForPublicCDD() {
            return this.EmployeesInPerimeter.filter(
                e => !!e.has_left_for_public_cdd
            )?.length || 0
        },
        LeftForPublicCDI() {
            return this.EmployeesInPerimeter.filter(
                e => !!e.has_left_for_public_cdi
            )?.length || 0
        },
        LeftForPublicInterim() {
            return this.EmployeesInPerimeter.filter(
                e => !!e.has_left_for_public_interim
            )?.length || 0
        },

        LeftForAdapted() {
            return this.EmployeesInPerimeter.filter(
                e => !!e.has_left_for_adapted
            )?.length || 0
        },
        LeftForAdaptedCDD() {
            return this.EmployeesInPerimeter.filter(
                e => !!e.has_left_for_adapted_cdd
            )?.length || 0
        },
        LeftForAdaptedCDI() {
            return this.EmployeesInPerimeter.filter(
                e => !!e.has_left_for_adapted_cdi
            )?.length || 0
        },
        LeftForAdaptedInterim() {
            return this.EmployeesInPerimeter.filter(
                e => !!e.has_left_for_adapted_interim
            )?.length || 0
        }
    },

    methods: {
        loadSkills() {
            this.skillsLoading = true;
            window
                .axios({
                    method: "get",
                    url: "/api/referentiel/skills",
                    params: { perpage: 9999, include: [] }
                })
                .then(response => {
                    this.skills = response.data.data;
                    this.skillsLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        loadAdminOccupationSkills() {
            this.adminoccupationskillsLoading = true;
            window
                .axios({
                    method: "get",
                    url: "/api/admin/occupation-skills",
                    params: { perpage: 9999, sortBy: {"title": "asc"}, include: [] }
                })
                .then(response => {
                    this.adminoccupationskills = response.data.data;
                    this.adminoccupationskillsLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        loadOccupationSkills() {
            this.occupationskillsLoading = true;
            window
                .axios({
                    method: "get",
                    url: "/api/referentiel/occupation-skills",
                    params: { perpage: 9999, sortBy: {"title": "asc"}, include: [] }
                })
                .then(response => {
                    this.occupationskills = response.data.data;
                    this.occupationskillsLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        loadJobs() {
            this.jobsLoading = true;
            window.axios({
                method: 'get',
                url: '/api/referentiel/jobs',
                params: {perpage: 9999, include: []},
            })
            .then(response => {
                this.jobs = response.data.data;
                this.jobsLoading = false;
            })
            .catch((err) => {
                console.log(err)
                this.notifyError(err, `Une erreur est survenue`)
            })
        },
        loadOccupations() {
            this.occupationsLoading = true;
            window.axios({
                method: 'get',
                url: '/api/referentiel/occupations',
                params: {perpage: 9999, include: []},
            })
            .then(response => {
                this.occupations = response.data.data;
                this.occupationsLoading = false;
            })
            .catch((err) => {
                console.log(err)
                this.notifyError(err, `Une erreur est survenue`)
            })
        },
        loadEmployees() {
            this.employeesLoading = true;
            window
                .axios({
                    method: "get",
                    url: "/api/gestion/employees",
                    params: {
                        perpage: 9999,
                        include: ['activeServices', 'experiences', 'establishments'],
                        append: [
                            'has_left',
                            'has_left_for_ordinary',
                            'has_left_for_ordinary_cdd',
                            'has_left_for_ordinary_cdi',
                            'has_left_for_ordinary_interim',
                            'has_left_for_private',
                            'has_left_for_private_cdd',
                            'has_left_for_private_cdi',
                            'has_left_for_private_interim',
                            'has_left_for_public',
                            'has_left_for_public_cdd',
                            'has_left_for_public_cdi',
                            'has_left_for_public_interim',
                            'has_left_for_adapted',
                            'has_left_for_adapted_cdd',
                            'has_left_for_adapted_cdi',
                            'has_left_for_adapted_interim',
                        ]
                    }
                })
                .then(response => {
                    //console.log('employee', response.data.data)
                    this.employees = response.data.data;
                    this.employeesLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        loadEstablishments() {
            this.establishmentsLoading = true;
            window
                .axios({
                    method: "get",
                    url: "/api/referentiel/establishments",
                    params: {
                        perpage: 9999,
                        include: ['services']
                    }
                })
                .then(response => {
                    this.establishments = response.data.data;
                    let establishments = []
                    this.listOfServicesViewableFor("employees", this.user).forEach(service => {
                        let establishment = this.establishmentById(this.serviceById(service)?.establishment_id)
                        if (establishment && !establishments.map(e => e.id).includes(establishment.id)) {
                            establishments.push(establishment)
                        }
                    })

                    this.establishmentsFilter = establishments
                    this.establishmentsLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        establishmentById(id) {
            return this.establishments.find(el => el.id === id);
        },
    },
    created() {
        if (this.planAllow('indicateurs-activite')) {
            this.loadSkills();
            this.loadAdminOccupationSkills();
            this.loadOccupationSkills();
            this.loadJobs();
            this.loadOccupations();
            this.loadEmployees();
            this.loadEstablishments();
        }

    }
};
</script>
<style lang="scss" scoped>
.dashboardLink {
    cursor: pointer;
}

.vx-row {
    margin: 0 -1rem
}

::v-deep input {
    min-height: 43px;
    padding: 1rem 1.3rem;
    font-size:1.1rem;
}

::v-deep .vx-card {
    .vx-card {
        box-shadow: 0px 4px 25px 10px rgba(0, 0, 0, 0.1);
    }
}
</style>
