import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export const buildCVMixin = {
    data() {
        return {
            pdf: null,
            a4_width: 210,
            a4_height: 297,
            nextSidePositionY: 0,
            totalPage: 0
        }
    },
    methods: {
        async download() {

            this.pdf = new jsPDF({
                orientation: "p",
                unit: "mm",
                putOnlyUsedFonts: true
            });
            this.pdf.setFont("helvetica", "normal");
            this.pdf.setFontSize(8);

            let cv = this.$refs.cv;

            let countCV = 0;

            if (Array.isArray(cv)) {
                for (let e of cv) {
                    await this.make(e);

                    // On passe à la page suivante pour le prochain
                    countCV++;
                    if (countCV < cv.length) {
                        this.pdf.addPage();
                    }
                }
            } else {
                await this.make(cv);
            }

            this.pdf.save("cv.pdf");
            this.closeExport();
        },

        async make(el) {
            const margin = 10;
            const content_width = this.a4_width - 2 * margin;
            const content_height = this.a4_height - 2 * margin;

            let header = el.querySelectorAll("header[cv-header]")[0];
            let occupations = el.querySelectorAll("div[cv-occupations]")[0];
            let RAE = el.querySelectorAll("div[cv-rae]")[0];
            let occupationSkills = el.querySelectorAll(
                "div[cv-occupationSkills]"
            )[0];
            let skills = el.querySelectorAll("div[cv-skills]")[0];
            let w_jobs = el.querySelectorAll("div[cv-w_jobs]")[0];
            let experiences = el.querySelectorAll("div[cv-experiences]")[0];
            let immersions = el.querySelectorAll("div[cv-immersions]")[0];
            let trainings = el.querySelectorAll("div[cv-trainings]")[0];
            let startPage = this.pdf.internal.getCurrentPageInfo().pageNumber;
            let infosLeft = [
                occupations,
                RAE,
                skills,
                occupationSkills
            ].filter(e => e != undefined);
            let infosRight = [
                w_jobs,
                experiences,
                immersions,
                trainings
            ].filter(e => e != undefined);
            let i = 0;

            let header_positionX = 0 + margin;

            // Cv header
            let avatar_height = 25;
            let avatar_width = avatar_height;
            this.nextSidePositionY = margin + 5;

            await this.addHeader(header, margin, avatar_height);
            header_positionX += avatar_width;

            let totalCvPage = 1; // Number of page by cv

            this.addTimeLine(margin, header_positionX, true);

            // For each cv side
            for (let e of [infosLeft, infosRight]) {
                let align = "right";
                this.pdf.setPage(startPage);
                i++;
                let size = 0.45; // Largeur du bloc sur la page, en pourcentage
                let offsetSide = 10;
                let width = content_width * size - offsetSide;
                let left = margin;
                let offsetTop = 12;

                if (i == 2) {
                    // Left side
                    align = "left";
                    offsetTop = 5;
                    left = width + margin + 2 * offsetSide;
                    size = 0.55;
                    width = content_width * size - offsetSide;
                }

                this.nextSidePositionY = header_positionX + offsetTop;
                let positionX = margin + width;
                if (align == "left") {
                    positionX = left;
                } else {
                    positionX = margin + width;
                }

                // For each part by side
                for (let el of e) {
                    let currentColor = "black";
                    for (let m = 0; m < el.children.length; m++) {
                        let pageCurrent = this.pdf.internal.getCurrentPageInfo()
                            .pageNumber;

                        // check for new page
                        if (this.nextSidePositionY + 5 > content_height) {
                            if (pageCurrent == totalCvPage) {
                                this.nextSidePositionY = margin;
                                this.pdf.addPage();
                                totalCvPage++;
                                this.addTimeLine(
                                    margin,
                                    header_positionX,
                                    false
                                );
                            } else {
                                this.pdf.setPage(pageCurrent + 1);
                                this.nextSidePositionY = margin + 5;
                            }
                        }

                        currentColor = getComputedStyle(el.children[m]).color;

                        if (el.children[m].tagName == "H2") {
                            // Draw top line and display h2 inner text
                            this.pdf.setFont("helvetica", "bold");
                            this.pdf.setDrawColor(currentColor);
                            this.pdf.setLineWidth(0.5);
                            this.nextSidePositionY += 8;
                            if (align == "left") {
                                this.pdf.line(
                                    left + width,
                                    this.nextSidePositionY,
                                    positionX,
                                    this.nextSidePositionY,
                                    "FD"
                                );
                            } else {
                                this.pdf.line(
                                    left,
                                    this.nextSidePositionY,
                                    positionX,
                                    this.nextSidePositionY,
                                    "FD"
                                );
                            }
                            this.pdf.setTextColor(currentColor);
                            this.pdf.setFontSize(11);
                            this.nextSidePositionY += 8;
                            this.addText(
                                el.children[m].innerText,
                                width,
                                positionX,
                                align
                            );
                            await this.addTimeLineDot(el);
                            this.nextSidePositionY += 2;
                        } else if (el.children[m].tagName == "DIV") {
                            let elem = el.children[m];
                            this.nextSidePositionY += 6;

                            for (let n = 0; n < elem.children.length; n++) {
                                let style = getComputedStyle(elem.children[n]);

                                if (elem.children[n].innerText) {
                                    // check for new page
                                    if (this.nextSidePositionY + 5 > content_height) {
                                        if (pageCurrent == totalCvPage) {
                                            this.nextSidePositionY = margin;
                                            this.pdf.addPage();
                                            this.pdf.setPage(pageCurrent + 1);
                                            totalCvPage++;
                                            this.addTimeLine(
                                                margin,
                                                header_positionX,
                                                false
                                            );
                                            pageCurrent = this.pdf.internal.getCurrentPageInfo().pageNumber
                                        } else {
                                            this.pdf.setPage(pageCurrent + 1);
                                            this.nextSidePositionY = margin + 5;
                                        }
                                    }

                                    // Display inner text
                                    let align = style.textAlign;
                                    let color = style.color;
                                    let fontWeight = style.fontWeight
                                        ? parseInt(style.fontWeight) > 500
                                            ? "bold"
                                            : "normal"
                                        : "normal";

                                    this.pdf.setFont("helvetica", fontWeight);
                                    this.pdf.setTextColor(color);
                                    this.pdf.setFontSize(9);
                                    this.nextSidePositionY += 5;

                                    let position;
                                    if (align == "left") {
                                        position = left;
                                    } else {
                                        position = margin + width;
                                    }

                                    if (elem.children[n].className == "addMargin") {
                                        
                                        // only for cv-occupationSkills
                                        this.addText(
                                            elem.children[n].innerText,
                                            width,
                                            position,
                                            align
                                        );
                                        this.nextSidePositionY += 5;
                                    } else if (elem.children[n].tagName == "UL") {
                                        // Ajout SAVOIR-FAIRE
                                        for (let item of elem.children[n].children) {
                                            this.addText(
                                                item.innerText,
                                                width,
                                                position,
                                                align
                                            );
                                            this.nextSidePositionY += 5;

                                            // Saut de page si besoin
                                            if (this.nextSidePositionY + 5 >  content_height) {
                                                if (pageCurrent == totalCvPage) {
                                                    this.nextSidePositionY = margin;
                                                    this.pdf.addPage();
                                                    totalCvPage++;

                                                    pageCurrent = this.pdf.internal.getCurrentPageInfo().pageNumber

                                                    this.addTimeLine(
                                                        margin,
                                                        header_positionX,
                                                        false
                                                    );
                                                } else {
                                                    this.pdf.setPage(pageCurrent + 1);
                                                    this.nextSidePositionY = margin + 5;
                                                }
                                            }
                                        }
                                    } else {
                                        let text = elem.children[n].innerText;

                                        this.addText(
                                            text,
                                            width,
                                            position,
                                            align
                                        );

                                         // RAE qualifiante (logo)
                                         if (
                                            elem.children[n].querySelectorAll(
                                                ".is-graduate-rae"
                                            ).length > 0
                                        ) {
                                            let image = elem.children[
                                                n
                                            ].querySelectorAll(
                                                ".is-graduate-rae"
                                            )[0];
                                            let graduate_rae = await this.getImageFromUrl(
                                                image.src
                                            );
                                            this.pdf.addImage(
                                                graduate_rae,
                                                "PNG",
                                                10,
                                                this.nextSidePositionY,
                                                10,
                                                10
                                            );
                                        }

                                        // Formation qualifiante (logo)
                                        if (
                                            elem.children[n].querySelectorAll(
                                                ".is-graduate"
                                            ).length > 0
                                        ) {
                                            let image = elem.children[
                                                n
                                            ].querySelectorAll(
                                                ".is-graduate"
                                            )[0];
                                            let graduate = await this.getImageFromUrl(
                                                image.src
                                            );
                                            this.pdf.addImage(
                                                graduate,
                                                "PNG",
                                                180,
                                                this.nextSidePositionY,
                                                10,
                                                10
                                            );
                                        }
                                    }
                                } else if (
                                    elem.children[n].hasAttribute("data-rate")
                                ) {
                                    // By skills, draw 10 circles, filled or empty, depends on skills rating
                                    this.nextSidePositionY += 5;
                                    this.pdf.setDrawColor(currentColor);
                                    this.pdf.setFillColor(currentColor);
                                    let rate = elem.children[n].getAttribute(
                                        "data-rate"
                                    );
                                    let isFullCircle = true;
                                    let radius = 2;
                                    let positionCircleX = left + radius;
                                    for (let i = 0; i < 10; i++) {
                                        if (i >= rate) {
                                            isFullCircle = false;
                                        }
                                        this.pdf.setLineWidth(0.25);
                                        this.pdf.circle(
                                            positionCircleX,
                                            this.nextSidePositionY,
                                            radius,
                                            isFullCircle ? "FD" : "S"
                                        );
                                        positionCircleX += 7;
                                    }
                                    this.nextSidePositionY += 2;
                                }
                            }
                        }
                    }

                    this.pdf.setDrawColor(currentColor);
                    this.pdf.setLineWidth(0.5);
                    this.nextSidePositionY += 10;
                    let position;
                    if (align == "left") {
                        position = this.a4_width - margin;
                    } else {
                        position = margin + width;
                    }
                    this.pdf.line(
                        left,
                        this.nextSidePositionY,
                        position,
                        this.nextSidePositionY,
                        "FD"
                    );
                }
            }

            this.totalPage += totalCvPage;
            this.addFooters(this.totalPage, totalCvPage, margin);
        },

        // Add dot/shape image, in front of each major part of the cv (occupations, skills, jobs etc...)
        async addTimeLineDot(el) {
            let icon;
            let iconWidth = 4;

            switch (true) {
                case el.hasAttribute("cv-occupations"): // Metiers
                    // full circle
                    icon = document.querySelectorAll(
                        "div[cv-occupations] .icon"
                    )[0];
                    break;
                case el.hasAttribute("cv-skills"): // Compétences
                    // full circle
                    icon = document.querySelectorAll("div[cv-skills] .icon")[0];
                    break;
                case el.hasAttribute("cv-experiences"): // Experiences
                    // empty circle
                    icon = document.querySelectorAll(
                        "div[cv-experiences] .icon"
                    )[0];
                    break;
                case el.hasAttribute("cv-w_jobs"): // Postes souhaités
                    // star icon
                    icon = document.querySelectorAll("div[cv-w_jobs] .icon")[0];
                    iconWidth = 5;
                    break;
                case el.hasAttribute("cv-immersions"): // Immersions
                    // full diamond
                    icon = document.querySelectorAll(
                        "div[cv-immersions] .icon"
                    )[0];
                    iconWidth = 5;
                    break;
                case el.hasAttribute("cv-rae"): // RAE
                    // full square
                    icon = document.querySelectorAll(
                        "div[cv-rae] .icon"
                    )[0];
                    break;
                case el.hasAttribute("cv-trainings"): // Formations
                    // full square
                    icon = document.querySelectorAll(
                        "div[cv-trainings] .icon"
                    )[0];
                    break;
                case el.hasAttribute("cv-occupationSkills"): // Savoir-faire
                    // full circle
                    icon = document.querySelectorAll(
                        "div[cv-occupationSkills] .icon"
                    )[0];
                    break;
                default:
                    icon = document.querySelectorAll(
                        "div[cv-experiences] .icon"
                    )[0];
                    break;
            }

            await html2canvas(icon).then(canvas => {
                canvas.getContext("2d");
                let canvasHeight = (canvas.height * iconWidth) / canvas.width;
                this.pdf.addImage(
                    canvas,
                    "PNG",
                    this.a4_width * 0.45 - iconWidth / 2,
                    this.nextSidePositionY - iconWidth + 1,
                    iconWidth,
                    canvasHeight
                );
            });
        },

        addText(innerText, width, position, align) {
            let text = this.pdf.splitTextToSize(innerText, width);
            this.pdf.text(text, position, this.nextSidePositionY, {
                align: align
            });
            if (text.length > 1) {
                this.nextSidePositionY += 5 * (text.length - 1) - text.length;
            }
        },

        // Draw vertical line in the middle of the page
        addTimeLine(margin, headerHeight, hasHeader = false) {
            if (hasHeader) {
                headerHeight += 5;
            } else {
                headerHeight = margin;
            }
            this.pdf.setFillColor(223, 223, 223);
            this.pdf.setDrawColor(223, 223, 223);
            this.pdf.line(
                this.a4_width * 0.45,
                headerHeight,
                this.a4_width * 0.45,
                this.a4_height - 2 * margin,
                "FD"
            );
        },

        async addHeader(header, margin, avatar_height) {
            // avatar
            let avatar_width = avatar_height;
            let url = header.children[0].children[0].src;
            let employeeAvatar = await this.getImageFromUrl(url);
            this.pdf.addImage(
                employeeAvatar,
                "JPEG",
                margin,
                margin,
                avatar_width,
                avatar_height
            );

            // content
            let texts = header.children[1].children;
            let avatarContainer = avatar_width + 2 * margin;
            for (let text of texts) {
                this.pdf.setFontSize(9);
                if (text.tagName == "H1") {
                    this.pdf.setFontSize(16);
                }
                let style = getComputedStyle(text);
                let align = style.textAlign;
                let color = style.color;
                let fontWeight = style.fontWeight
                    ? parseInt(style.fontWeight) > 500
                        ? "bold"
                        : "normal"
                    : "normal";

                this.pdf.setFont("helvetica", fontWeight);
                this.pdf.setTextColor(color);
                this.addText(
                    text.innerText,
                    this.a4_width - avatarContainer - margin,
                    avatarContainer,
                    align
                );
                this.nextSidePositionY += 5;
                if (text.tagName == "H1") {
                    this.nextSidePositionY += 3;
                }
            }
        },

        addFooters(totalPage, totalCvPage, margin) {
            this.pdf.setFont("helvetica", "normal");
            for (let i = 1; i <= totalCvPage; i++) {
                this.pdf.setPage(totalPage - totalCvPage + i);
                this.pdf.setTextColor(0, 0, 0);
                this.pdf.setFillColor(255, 255, 255);
                this.pdf.rect(
                    0,
                    this.a4_height - margin,
                    this.a4_width,
                    this.a4_height,
                    "F"
                );
                this.pdf.text(
                    "Produit par MySkilliz RH",
                    margin,
                    this.a4_height - margin / 2,
                    { align: "left" }
                );
                this.pdf.text(
                    `Page ${i}/${totalCvPage}`,
                    this.a4_width - margin,
                    this.a4_height - margin / 2,
                    { align: "right" }
                );
                this.pdf.setFillColor(0, 0, 0);
                this.pdf.line(
                    margin,
                    this.a4_height - margin,
                    this.a4_width - margin,
                    this.a4_height - margin,
                    "F"
                );
            }
        },
    }
};
