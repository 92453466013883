<template>
    <div class="con-exemple-prompt mb-6 scrollBug">
        <!-- Nom -->
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Nom d'établissement* :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input
                    v-if="type != 'show'"
                    v-model="resource.name"
                    name="name"
                    class="w-full"
                />
                <b v-else>{{ resource.name }}</b>
            </div>
        </div>

        <!-- Choix du type -->
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Type d'établissement* :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Multiselect
                    v-if="type != 'show'"
                    v-model="resource.type"
                    name="establishmentType"
                    :options="establishmentTypes"
                    placeholder="Recherche ..."
                    select-label
                    select-group-label
                    selected-label
                    deselect-label
                    deselect-group-label
                />
                <span v-else>{{ resource.type }}</span>
            </div>
        </div>

        <div v-show="resource.type === 'Autre'" class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Précisez le type d'établissement :</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input
                    v-model="resource.customType"
                    class="w-full"
                    name="customType"
                />
            </div>
        </div>

        <!-- Adresse postale -->
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Adresse postale* :</span>
            </div>

            <div class="vx-col sm:w-2/3 w-full">
                <AddressAutoComplete
                    name="establishmentAddress"
                    :default="defaultAddress"
                    placeholder="Rechercher"
                    class="w-full"
                    @result="mapBoxAddressResult($event)"
                />
            </div>
        </div>

        <!-- Logo -->
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Logo : </span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <avatar-cropper
                    trigger="#pick-avatar"
                    :labels="avatarLabels"
                    :upload-handler="avatarSubmited"
                />
                <img
                    v-if="resource.logo"
                    id="pick-avatar"
                    :src="resource.logo"
                    width="70"
                    height="70"
                />
                <vs-avatar
                    v-else
                    id="pick-avatar"
                    size="70px"
                    :text="resource.name"
                    :color="generateColor(resource.name)"
                />
            </div>
        </div>

        <!-- Utilisateur référent -->
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Utilisateur référent : </span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Multiselect
                    v-model="resource.reference"
                    :options="users"
                    :multiple="false"
                    placeholder="Recherche ..."
                    track-by="id"
                    label="label"
                    select-label
                    selected-label
                    deselect-label
                    :loading="usersLoading"
                />
            </div>
        </div>

        <!-- Premier service -->
        <div v-if="type == 'create'" class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <span>Nom du premier service : </span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input
                    v-model="resource.first_service"
                    class="w-full"
                    name="firstService"
                />
            </div>
        </div>

        <!-- Licences -->
        <vs-row v-if="resource.establishment_licence">
            <vs-col class="w-full">
                <h3>Licences :</h3>
                <table class="table" id="licence-detail">
                    <thead>
                        <tr>
                            <th>Nb total</th>
                            <th>Nb de licences utilisées</th>
                            <th>Nb de licences disponibles</th>
                            <th>Date anniversaire du contrat</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ resource.establishment_licence.quantity }}</td>
                            <td>{{ resource.establishment_licence.consumed }}</td>
                            <td>{{ resource.establishment_licence.available }}</td>
                            <td>{{ formatDate(licence.anniversary) }}</td>
                        </tr>
                    </tbody>
                </table>
            </vs-col>
        </vs-row>

        <vs-row>
            <!-- SF dans auto-eval du Portail -->
            <div v-if="resource.mobiliteIsOn || true" class="vx-col sm:w-1/3 w-full mt-10">
                <vs-row vs-align="center">
                    <vs-col vs-w="6">
                        <span>Auto-évaluation des savoir-faire</span>
                    </vs-col>
                    <vs-col vs-w="6">
                        <vs-switch
                            v-model="resource.portail_occupation_skill"
                            name="establishmentPortailOccSkills"
                        />
                    </vs-col>
                </vs-row>
            </div>

            <!-- Eval des SF uniquement -->
            <div class="vx-col sm:w-1/3 w-full mt-10">
                <vs-row vs-align="center">
                    <vs-col vs-w="6">
                        <span>Evaluation des savoir-faire uniquement</span>
                    </vs-col>
                    <vs-col vs-w="6">
                        <vs-switch
                            v-model="resource.occupation_skills_rating_only"
                            name="occupationSkillsRatingOnly"
                        />
                    </vs-col>
                </vs-row>
            </div>
        </vs-row>

        <!-- Mobilité -->
        <!-- <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/3 w-full">
                <vs-switch
                    v-model="resource.visibility"
                    name="establishmentMobilite"
                    style="display: inline; vertical-align: middle;"
                />
                <span>Visible dans la vue mobilité</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-row vs-align="center">
                    <vs-col vs-w="4">
                        <span>Portée par défaut des annonces: </span>
                    </vs-col>
                    <vs-col vs-w="8">
                        <Multiselect
                            v-model="resource.public_announcements"
                            name="establishmentScope"
                            :options="announcementsScopes"
                            track-by="value"
                            label="text"
                            placeholder="Types d'annonce ..."
                            select-label=""
                            selected-label=""
                            deselect-label=""
                            :allow-empty="false"
                        />
                    </vs-col>
                </vs-row>
            </div>
        </div> -->

        <!-- Description -->
        <!--<div class="vx-row mb-6">
            <div class="vx-col sm:w-4/5 w-full">
                <vs-textarea
                    v-model="resource.description"
                    label="Description"
                />
            </div>
            <div class="vx-col sm:w-1/5 w-full">
                <avatar-cropper
                    :id="'cropper'"
                    :trigger="'#pick-photo'"
                    :labels="avatarLabels"
                    :upload-handler="photoSubmited"
                    :cropper-options="{
                        aspectRatio: '16:9',
                        autoCropArea: 1,
                        viewMode: 1,
                        movable: false,
                        zoomable: false
                    }"
                />
                <img
                    v-if="resource.photo"
                    :id="'pick-photo'"
                    :src="resource.photo"
                    width="120px"
                />

                <div
                    v-else
                    :id="'pick-photo'"
                    style="text-align:center; font-size: 20px; background-color: grey; color: white; width: 120px; height: 120px;"
                >
                    Photo
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import AddressAutoComplete from "@components/form/AddressAutoComplete.vue";
import AvatarCropper from "vue-avatar-cropper";
import Datepicker from "vuejs-datepicker";
import { locale } from "vuejs-datepicker/dist/locale";
import randomColor from 'randomcolor'

export default {
    name: "EstablishmentForm",
    components: {
        Multiselect,
        AddressAutoComplete,
        AvatarCropper,
        Datepicker
    },
    props: {
        value: {
            required: false,
            type: Object
        },
        type: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            log: console.log,
            licence: window.Laravel.mainLicence,
            locale: locale,
            resource: {},
            establishmentTypes: ["EA", "ESAT", "IME", "Autre"],
            avatarLabels: { submit: "Soumettre", cancel: "Annuler" },
            users: [],
            usersLoading: true
        };
    },
    watch: {
        value(newValue, oldValue) {
            if (newValue === oldValue) return;
            this.initResource();
        },
        resource: {
            handler() {
                this.value.name                          = this.resource.name;
                this.value.type                          = this.resource.type;
                this.value.customType                    = this.resource.customType;
                this.value.logo                          = this.resource.logo;
                this.value.photo                         = this.resource.photo;
                this.value.address                       = this.resource.address;
                this.value.reference                     = this.resource.reference;
                this.value.public_announcements          = this.resource.public_announcements;
                this.value.visibility                    = this.resource.visibility ? 1 : 0;
                this.value.portail_occupation_skill      = this.resource.portail_occupation_skill ? 1 : 0;
                this.value.occupation_skills_rating_only = this.resource.occupation_skills_rating_only ? 1 : 0;
                this.value.licences                      = this.resource.licences;
                this.value.description                   = this.resource.description;
                this.value.first_service                 = this.resource.first_service;

                if (this.resource.logoBlob) {
                    this.value.logoBlob = this.resource.logoBlob;
                }

                if (this.resource.photoBlob) {
                    this.value.photoBlob = this.resource.photoBlob;
                }

                this.$emit("input", this.value);
            },
            deep: true
        },
        hasError(value) {
            this.$emit("error", value);
        }
    },
    computed: {
        hasError() {
            let error =
                !this.resource.name 
                || !this.resource.type 
                //|| !this.resource.reference
                || !this.resource.address; 
            return error;
        },

        defaultAddress() {
            return this.resource.address["place_name_fr-FR"]
                ? this.resource.address["place_name_fr-FR"]
                : null;
        },

        announcementsScopes() {
            return [
                { value: 0, text: "Interne" },
                { value: 1, text: "Public" }
            ];
        }
    },
    methods: {
        initResource() {
            let reference = this.value.reference;
            let type = this.value.type;
            let customType = null;

            if (reference) {
                reference.label = `${reference.name} (${reference.email})`;
            }

            // Traitement du cas de type Autre avec detail
            const regType = new RegExp('Autre:.*');
            if (regType.test(type)) {
                let splited = type.split(': ');
                type        = splited[0];
                customType  = splited[1];
            }

            this.resource = {
                logoBlob: null,
                photoBlob: null,
                name: this.value.name,
                type: type,
                customType: customType,
                logo: this.value.logo,
                photo: this.value.photo,
                address: this.value.address,
                reference: reference,
                public_announcements: this.value.public_announcements,
                visibility: this.value.visibility,
                licences: this.value.licences,
                description: this.value.description,
                establishment_licence: this.value.establishment_licence,
                portail_occupation_skill: this.value.portail_occupation_skill,
                occupation_skills_rating_only: this.value.occupation_skills_rating_only
            };

            // Portée des annonces
            // this.resource.public_announcements = this.announcementsScopes[
            //     this.resource.public_announcements
            // ];
        },

        async loadUsers() {
            return window
                .axios({
                    method: "get",
                    url: "/api/referentiel/users",
                    params: {
                        perpage: 9999,
                        include: []
                    }
                })
                .then(response => {
                    let users = response.data.data;

                    if (users.length == 0) {
                        return;
                    }

                    users.slice().map(u => {
                        u.label = `${u.name} (${u.email})`;
                        return u;
                    });

                    this.users = users;
                    this.usersLoading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },

        mapBoxAddressResult(event) {
            this.resource.address = event;

            if (
                this.resource.address["place_name_fr"] &&
                !this.resource.address["place_name_fr-FR"]
            ) {
                this.resource.address[
                    "place_name_fr-FR"
                ] = this.resource.address["place_name_fr"];
            }

            if (
                this.resource.address["text_fr"] &&
                !this.resource.address["text_fr-FR"]
            ) {
                this.resource.address["text_fr-FR"] = this.resource.address[
                    "text_fr"
                ];
            }
        },

        avatarSubmited(ev) {
            ev.getCroppedCanvas({ width: 512, height: 512 }).toBlob(blob => {
                this.resource.logo = URL.createObjectURL(blob);
                this.resource.logoBlob = blob;
            });
        },

        photoSubmited(ev) {
            ev.getCroppedCanvas({ width: 1920, height: 1080 }).toBlob(blob => {
                this.resource.photo = URL.createObjectURL(blob)
                this.resource.photoBlob = blob
            })
        },

        generateColor(str) {
            const color = randomColor({
                seed: str
            });
            return color;
        }
    },
    async created() {
        this.initResource();

        this.localLoading();
        await this.loadUsers();
        this.endLocalLoading();
    }
};
</script>

<style lang="scss" scoped>
.avatar-cropper
    .avatar-cropper-container
    .avatar-cropper-footer
    .avatar-cropper-btn:hover {
    background-color: #8d1f54;
}

.cropper-point {
    background-color: #8d1f54;
}

.cropper-line {
    background-color: #8d1f54;
}

.cropper-view-box {
    outline: 1px solid #8d1f54;
}

#licence-detail {
        width: 100%;
        border: 3px solid #dbdbdb;
        padding: 10px;
    }

    #licence-detail th, #licence-detail td {
        font-size: 1.2em;
        text-align: right;
        padding: 6px 10px;
    }
</style>
