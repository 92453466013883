<template>
    <section class="experience">
        <h3 class="mb-8">Evaluateurs tiers :</h3>

        <vs-table
            :data="employee.evaluators || []"
            no-data-text="Aucune donnée à afficher"
            style="width:100%;"
        >
            <template slot="thead">
                <vs-th sort-key="last_name">
                    Nom
                </vs-th>
                <vs-th sort-key="first_name">
                    Prénom
                </vs-th>
                <vs-th sort-key="email">
                    Email
                </vs-th>
                <vs-th sort-key="type">
                    Type d'évaluation
                </vs-th>
                <vs-th sort-key="services">
                    Postes à évaluer
                </vs-th>
                <vs-th sort-key="services">
                    Métiers à évaluer
                </vs-th>
                <vs-th sort-key="last">
                    Dernière évaluation transmise le
                </vs-th>
                <vs-th v-if="canUpdate()">
                    Actions
                </vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr
                    v-for="(item, indextr) in data"
                    :key="indextr"
                    :data="item"
                >
                    <vs-td :data="item.last_name">
                        {{ item.last_name }}
                    </vs-td>
                    <vs-td :data="item.first_name">
                        {{ item.first_name }}
                    </vs-td>
                    <vs-td :data="item.email">
                        {{ item.email }}
                    </vs-td>
                    <vs-td>
                        <vs-chip
                            v-for="(el, index) in JSON.parse(item.pivot.eval_types)"
                            :key="'eval-type-'+index"
                        >
                            <b>{{ el.title }}</b>
                        </vs-chip>
                    </vs-td>
                    <vs-td>
                        <vs-chip
                            v-for="(el, index) in JSON.parse(item.pivot.jobs)"
                            :key="'eval-job-'+index"
                        >
                            <b>{{ el.title }}</b>
                        </vs-chip>
                    </vs-td>
                    <vs-td>
                        <vs-chip
                            v-for="(el, index) in JSON.parse(item.pivot.occupations)"
                            :key="'eval-occupation-'+index"
                        >
                            <b>{{ el.title }}</b>
                        </vs-chip>
                    </vs-td>
                    <vs-td>
                        {{ (employee.employee_ratings.find(r => r.created_by === item.id) ? employee.employee_ratings.find(r => r.created_by === item.id).rating_date : "-")  | date }}
                    </vs-td>
                    <vs-td v-if="canUpdate()">
                        <vs-button
                            v-if="can('update', 'users')"
                            :id="'modifyTiers_' + indextr"
                            v-tooltip="'Modifier cet évaluateur'"
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="icon-edit"
                            @click="updateTiers(item)"
                        />
                        <vs-button
                            v-else
                            :id="'deleteTiers_' + indextr"
                            v-tooltip="'Enlever cet évaluateur'"
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="icon-trash"
                            @click="deleteTiers(item)"
                        />
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </section>
</template>

<script>
export default {
    name: "EmployeeEvaluators",
    components: {},
    props: {
        employee: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            evaluator: {}
        };
    },
    watch: {},
    computed: {},
    methods: {
        async updateTiers(data) {
            await this.loadUser(data.id)
            this.$emit('update-tiers', this.evaluator);
        },
        async deleteTiers(data) {
            this.employee.evaluators.splice(
                this.employee.evaluators.map(e => e.id).indexOf(data.id),
                1
            )
            this.$emit('delete-tiers', {evaluator:data, employee:this.employee});
        },
        async loadUser(id) {
            return window.axios({
                    method: "get",
                    url: "/api/referentiel/users",
                    params: {
                        perpage: 9999,
                        include: ['roles.permissions',
                            'permissions',
                            'establishments.services',
                            'services',
                            'employees'
                        ],
                        filter: 'id|=|'+id
                    }
                })
                .then(response => {
                    this.evaluator = response.data.data[0] ?? {};
                })
                .catch(err => {
                    console.log(err);
                    this.notifyError(err, `Une erreur est survenue`);
                });
        },
        canUpdate() {
            return this.canMulti("update", "employees", this.employee.services.map(s => s.id));
        },
    }
};
</script>

<style lang="scss" scoped></style>
