<template>
    <div>
        <vx-card title="Paramétrer le projet personnalisé">
            
            <!-- Nom -->
            <div class="mb-10">
                <vs-row>
                    <vs-col vs-w="12">
                        <p>Modifier le nom donné aux projets personnalisés dans l'application (singulier & pluriel):</p>
                        <br />
                        <vx-input-group>
                            <vs-input
                                v-model="ppNames.singular"
                                name="textZone"
                                class="w-full"
                                placeholder="Nom au singulier"
                                label-placeholder="Nom au singulier"
                                required
                            />
                            <vs-input
                                v-model="ppNames.plural"
                                name="textZone"
                                class="w-full"
                                placeholder="Nom au pluriel"
                                label-placeholder="Nom au pluriel"
                                required
                            />

                            <template slot="append">
                                <div class="append-text btn-addon">
                                    <vs-button
                                        icon-pack="feather"
                                        icon="icon-edit"
                                        @click.prevent="updateNames"
                                        :disabled="ppNamesError"
                                    >
                                        Modifier
                                    </vs-button>
                                </div>
                            </template>
                        </vx-input-group>
                    </vs-col>
                </vs-row>
            </div>

            <!-- Objectifs -->
            <div class="mb-10">
                <vs-row class="mb-3">
                    <vs-col>
                        <h5>Objectifs</h5>
                    </vs-col>
                </vs-row>

                <vs-row v-if="items.length < 10">
                    <vs-col vs-w="12">
                        <p>Ajouter un état (Autres objectifs) :</p>
                        <vx-input-group>
                            <vs-input
                                v-model="state.name"
                                name="textZone"
                                class="w-full"
                                placeholder="Nom"
                                required
                            />

                            <template slot="append">
                                <div class="append-text btn-addon">
                                    <vs-button
                                        id="addTextZone"
                                        icon-pack="feather"
                                        icon="icon-plus"
                                        @click.prevent="createState"
                                    >
                                        Ajouter
                                    </vs-button>
                                </div>
                            </template>
                        </vx-input-group>
                    </vs-col>
                </vs-row>

                <vs-row>
                    <vs-col
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="12"
                    >
                        <vs-table
                            v-if="states.length > 0"
                            max-items="10"
                            :data="statesOrdered"
                            :no-data-text="$constants.TABLE_NODATA_TEXT"
                            style="width:100%"
                        >
                            <template slot="thead">
                                <!-- <vs-th style="width: 15px"></vs-th> -->
                                <vs-th>
                                    #
                                </vs-th>
                                <vs-th>
                                    Nom
                                </vs-th>
                                <vs-th v-show="states.length > 1">
                                    Action
                                </vs-th>
                            </template>

                            <template slot-scope="{ data }">
                                <tr
                                    v-for="(item, i) in data"
                                    :key="i"
                                    :data="item"
                                    :class="!!dragging && dragging.id == item.id ? 'dragging' : 'waiting-drag'"
                                >
                                    <!-- <vs-td>
                                        <div
                                            class="drag-icon"
                                            draggable="true"
                                            @dragstart="dragStart(item, $event)"
                                            @dragover="dragOver(item.order, $event)"
                                            @drop="drop(item.order, $event)"
                                        ></div>
                                    </vs-td> -->
                                    <vs-td :data="item.id">
                                        {{ item.id }}
                                    </vs-td>
                                    <vs-td :data="item.name">
                                        <div v-if="stateEditing == item.id">
                                            <vx-input-group>
                                                <vs-input
                                                    v-model="item.name"
                                                    name="textZone"
                                                    class="w-full"
                                                    placeholder="Nom"
                                                    required
                                                />

                                                <template slot="append">
                                                    <div class="append-text btn-addon">
                                                        <vs-button
                                                            icon-pack="feather"
                                                            icon="icon-edit"
                                                            @click.prevent="updateState(item)"
                                                        >
                                                            Enregistrer
                                                        </vs-button>
                                                    </div>
                                                </template>
                                            </vx-input-group>
                                        </div>
                                        <div v-else>
                                            {{ item.name }}
                                        </div>
                                    </vs-td>
                                    <vs-td v-if="items.length > 1">
                                        <vs-button
                                            v-tooltip="'Modifier'"
                                            color="primary"
                                            type="border"
                                            icon-pack="feather"
                                            icon="icon-edit"
                                            @click="stateEditing = item.id"
                                        />

                                        <vs-button
                                            v-tooltip="'Supprimer'"
                                            color="primary"
                                            type="border"
                                            icon-pack="feather"
                                            icon="icon-trash"
                                            style="margin-left: 1rem;"
                                            @click="deleteState(item)"
                                        />
                                    </vs-td>
                                </tr>
                            </template>
                        </vs-table>
                    </vs-col>
                </vs-row>
            </div>

            <!-- Synthèse -->
            <div class="mb-10">
                <vs-row class="mb-3">
                    <vs-col>
                        <h5>Synthèse</h5>
                    </vs-col>
                </vs-row>

                <vs-row v-if="items.length < 10">
                    <vs-col vs-w="12">
                        <p>Ajouter une zone de texte :</p>
                        <br />
                        <vx-input-group>
                            <vs-input
                                v-model="resource.name"
                                name="textZone"
                                class="w-full"
                                placeholder="Nom"
                                required
                            />

                            <template slot="append">
                                <div class="append-text btn-addon">
                                    <vs-button
                                        id="addTextZone"
                                        icon-pack="feather"
                                        icon="icon-plus"
                                        @click.prevent="createResource"
                                    >
                                        Ajouter
                                    </vs-button>
                                </div>
                            </template>
                        </vx-input-group>
                    </vs-col>
                </vs-row>

                <vs-row>
                    <vs-col
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-w="12"
                    >
                        <vs-table
                            v-if="items.length > 0"
                            max-items="10"
                            :data="itemsOrdered"
                            :no-data-text="$constants.TABLE_NODATA_TEXT"
                            style="width:100%"
                        >
                            <template slot="thead">
                                <vs-th style="width: 15px"></vs-th>
                                <vs-th>
                                    Ordre
                                </vs-th>
                                <vs-th>
                                    Nom
                                </vs-th>
                                <vs-th v-show="items.length > 1">
                                    Action
                                </vs-th>
                            </template>

                            <template slot-scope="{ data }">
                                <tr
                                    v-for="(item, i) in data"
                                    :key="i"
                                    :data="item"
                                    :class="!!dragging && dragging.id == item.id ? 'dragging' : 'waiting-drag'"
                                >
                                    <vs-td>
                                        <div
                                            class="drag-icon"
                                            draggable="true"
                                            @dragstart="dragStart(item, $event)"
                                            @dragover="dragOver(item.order, $event)"
                                            @drop="drop(item.order, $event)"
                                        ></div>
                                    </vs-td>
                                    <vs-td :data="item.order">
                                        {{ item.order }}
                                    </vs-td>
                                    <vs-td :data="item.name">
                                        <div v-if="resourceEditing == item.id">
                                            <vx-input-group>
                                                <vs-input
                                                    v-model="item.name"
                                                    name="textZone"
                                                    class="w-full"
                                                    placeholder="Nom"
                                                    required
                                                />

                                                <template slot="append">
                                                    <div class="append-text btn-addon">
                                                        <vs-button
                                                            icon-pack="feather"
                                                            icon="icon-edit"
                                                            @click.prevent="updateResource(item)"
                                                        >
                                                            Enregistrer
                                                        </vs-button>
                                                    </div>
                                                </template>
                                            </vx-input-group>
                                        </div>
                                        <div v-else>
                                            {{ item.name }}
                                        </div>
                                    </vs-td>
                                    <vs-td v-if="items.length > 1">
                                        <vs-button
                                            v-tooltip="'Modifier'"
                                            color="primary"
                                            type="border"
                                            icon-pack="feather"
                                            icon="icon-edit"
                                            @click="editResource(item.id)"
                                        />

                                        <vs-button
                                            v-tooltip="'Supprimer'"
                                            color="primary"
                                            type="border"
                                            icon-pack="feather"
                                            icon="icon-trash"
                                            style="margin-left: 1rem;"
                                            @click="deleteResource(item)"
                                        />
                                    </vs-td>
                                </tr>
                            </template>
                        </vs-table>
                    </vs-col>
                </vs-row>
            </div>

            <!-- Divers -->
            <div>
                <vs-row class="mb-3">
                    <vs-col>
                        <h5>Divers</h5>
                    </vs-col>
                </vs-row>

                <!-- Switch : Enabled occupations_skills ratings -->
                <vs-row class="mb-10" vs-type="flex" vs-w="12">
                    <vs-col vs-lg="3" vs-sm="10">
                        <p>Activer la notation des savoir-faire</p>
                    </vs-col>
                    <vs-col vs-lg="9" vs-sm="2" v-if="occupationsSkillsRating">
                        <vs-switch
                            v-model="enabledOccupationsSkillsRating"
                            @input="updateOccupationsSkillsRating"
                        />
                    </vs-col>
                </vs-row>

                <!-- Switch : Enabled sort skill on id (instead of title) in the ratings (same as Portail) -->
                <vs-row class="mb-10" vs-type="flex" vs-w="12">
                    <vs-col vs-lg="3" vs-sm="10">
                        <p>Activer le tri des compétences dans une Catégorie par ordre de description alphabétique <small>(sinon par id croissant comme sur le Portail)</small></p>
                    </vs-col>
                    <vs-col vs-lg="9" vs-sm="2">
                        <vs-switch
                            v-model="enabledSkillSortByDescription"
                            @input="updateSkillSortByDescription"
                        />
                    </vs-col>
                </vs-row>
            </div>

        </vx-card>
        <vx-card class="mt-5" title="Paramétrer le bilan">
            <vs-row>
                <vs-col vs-w="12">
                    <p>Modifier le nom donné aux bilans dans l'application (singulier & pluriel):</p>
                    <br />
                    <vx-input-group>
                        <vs-input
                            v-model="srNames.singular"
                            name="textZone"
                            class="w-full"
                            placeholder="Nom au singulier"
                            label-placeholder="Nom au singulier"
                            required
                        />
                        <vs-input
                            v-model="srNames.plural"
                            name="textZone"
                            class="w-full"
                            placeholder="Nom au pluriel"
                            label-placeholder="Nom au pluriel"
                            required
                        />

                        <template slot="append">
                            <div class="append-text btn-addon">
                                <vs-button
                                    icon-pack="feather"
                                    icon="icon-edit"
                                    @click.prevent="updateNames"
                                    :disabled="srNamesError"
                                >
                                    Modifier
                                </vs-button>
                            </div>
                        </template>
                    </vx-input-group>
                </vs-col>
            </vs-row>
        </vx-card>

        <vx-card class="mt-5" title="Paramétrer les questionnaires du portail">
            <RatingQuestionnaires api="/api/referentiel/rating-questionnaires" />
        </vx-card>
    </div>
</template>

<script>

import RatingQuestionnaires from './RatingQuestionnaires.vue';
export default {
    name: "Parameters",
    components: {
        RatingQuestionnaires
    },
    props: {
        api: {
            type: String
        }
    },
    data() {
        return {
            resource: {name: null},
            ppNames: {
                singular: null,
                plural: null
            },
            srNames: {
                singular: null,
                plural: null
            },
            loading: false,
            enabledOccupationsSkillsRating: null,
            enabledSkillSortByDescription: null,
            resourceEditing: null,
            orderChanged: false,
            dragging: null,
            states: [],
            state: {
                name: null
            },
            stateEditing: null
        };
    },

    created() {
        this.apiParams = {
            sortBy: {"order": "asc"}
        };
        this.loadItems();
        this.loadStates();
        this.initOccupationsSkillsRatingsParam();
        this.initSkillSortByDescriptionParam()
        this.initNamings()
    },

    computed: {
        occupationsSkillsRating() {
            if (window.Laravel.settings) {
                return window.Laravel.settings.find(el => el.name == 'occupationSkillsRatings');
            }
            return null;
        },

        skillSortByDescription() {
            if (window.Laravel.settings) {
                return window.Laravel.settings.find(el => el.name == 'skillSortByDescription');
            }
            return null;
        },

        // ratingFields() {
        //     return this.items;
        // },
        itemsOrdered() {
            return this.items.sort((a, b) => a.order > b.order ? 1 : -1)
        },

        statesOrdered() {
            return this.states.sort((a, b) => a.order > b.order ? 1 : -1)
        },

        ppNamesError() {
            return this.ppNames.singular.length > 25 || this.ppNames.plural.length > 25
        },
        srNamesError() {
            return this.srNames.singular.length > 25 || this.srNames.plural.length > 25
        }
    },

    watch: {
        occupationsSkillsRating() {
            this.initOccupationsSkillsRatingsParam();
        },
        skillSortByDescription() {
            this.initSkillSortByDescriptionParam();
        },
        loading(value) {
            if (value) {
                this.$vs.loading();
            } else {
                this.$vs.loading.close();
            }
        },
    },

    methods: {
        createResource() {
            this.loading = true;

            let data = JSON.parse(JSON.stringify(this.resource));
            data.order = 1;

            this.apiCreate(
                data,
                resp => {
                    this.loadItems()
                    this.loading = false;
                    this.resource.name = null;
                },
                err => {
                    this.loading = false;
                    alert('Error !!');
                }
            );
        },

        editResource(id) {
            this.resourceEditing = id
        },

        updateResource(data, withoutNotification = false) {
            this.loading = true;

            this.apiUpdate({
                    name: data.name,
                    order: data.order
                },
                data.id,
                resp => {
                    this.loadItems()
                    this.loading = false;
                    this.resourceEditing = null;
                },
                err => {
                    this.loading = false;
                    alert('Error !!');
                },
                null,
                false,
                withoutNotification
            );
        },

        // updateOrderResource(data) {
        //     this.loading = true;

        //     this.apiUpdate({
        //             name: data.moving.name,
        //             order: data.to
        //         },
        //         data.moving.id,
        //         resp => {
        //             this.loadItems()
        //             this.loading = false;
        //         },
        //         err => {
        //             this.loading = false;
        //             alert('Error !!');
        //         },
        //     );
        // },

        deleteResource(item) {
            this.apiDelete(item.id, () => this.loadItems());
        },

        dragStart(item, ev) {
            console.log(ev);
            ev.dataTransfer.setData("Text", this.id);
            ev.dataTransfer.dropEffect = "move";
            this.dragging = item;
        },

        drop(to, ev) {
            ev.preventDefault();

            if (this.orderChanged) {
                let toUpdate = [];
                this.items.forEach((item, i) => {
                    toUpdate.push(this.updateResource(item, true));
                });

                Promise.all(toUpdate).then(() => {
                    this.notifySuccess('Succès', 'Ordre modifié avec succès !')
                    this.orderChanged = false;
                    this.dragging = null;
                })
            }
            //this.updateOrderResource({ moving: this.dragging, to: to });
        },

        dragOver(to, ev) {

            ev.preventDefault();

            // Modification de l'ordre en live
            const from = this.dragging.order;
            if (from != to) {
                this.items.forEach((item, i) => {
                    if (item.id == this.dragging.id) {
                        this.items[i].order = to;
                    } else if (from < to) {
                        if (item.order > from && item.order <= to) {
                            this.items[i].order--;
                        }
                    } else {
                        if (item.order < from && item.order >= to) {
                            this.items[i].order++;
                        }
                    }
                })

                this.orderChanged = true;
            }
        },

        initOccupationsSkillsRatingsParam() {
            if (this.occupationsSkillsRating) {
                this.enabledOccupationsSkillsRating = this.occupationsSkillsRating.value.enabled;
            }
        },
        initSkillSortByDescriptionParam() {
            //console.log(this.skillSortByDescription === null, this.skillSortByDescription)
            if (this.skillSortByDescription === null || this.skillSortByDescription === undefined) {
                this.updateSkillSortByDescription(true) //force set as true is not defined
                this.enabledSkillSortByDescription = true
            }
            else this.enabledSkillSortByDescription = this.skillSortByDescription.value.enabled
        },

        initNamings() {
            this.ppNames = this.projectNames
            this.srNames = this.skillRatingNames
        },

        updateOccupationsSkillsRating(value) {
            console.log(value)
            window.axios({
                method: 'patch',
                url: `/api/referentiel/settings/occupations-skills-rating`,
                data: {occupationsSkillsRating: value}
            })
            .then(response => {

                if (response.status === 200 && response.data != '') {
                    this.notifySuccess('Succès', 'Élement modifié avec succès !')
                } else {

                    this.notifyError('Erreur', `Une erreur est survenue lors de la modification`)
                }

                this.loading = false;
            })
            .catch((err, data) => {
                this.notifyErrorValidation(err)
                if (callbackError) {
                    callbackError(err.response)
                }
            })
        },

        updateSkillSortByDescription(value) {
            console.log(value)
            window.axios({
                method: 'patch',
                url: `/api/referentiel/settings/skills-sorting`,
                data: {skillSortByDescription: value}
            })
                .then(response => {

                    if (response.status === 200 && response.data != '') {
                        this.notifySuccess('Succès', 'Élement modifié avec succès !')
                    } else {

                        this.notifyError('Erreur', `Une erreur est survenue lors de la modification`)
                    }

                    this.loading = false;
                })
                .catch((err, data) => {
                    this.notifyErrorValidation(err)
                    if (callbackError) {
                        callbackError(err.response)
                    }
                })
        },

        updateNames(event) {
            window.axios({
                method: 'patch',
                url: `/api/referentiel/settings/store-namings`,
                data: {
                    ppName: this.ppNames.singular,
                    ppPluralName: this.ppNames.plural,
                    srName: this.srNames.singular,
                    srPluralName: this.srNames.plural,
                }
            })
            .then(response => {
                this.notifySuccess('Succès', 'Nommages modifiés avec succès !')
                this.loading = false;
            })
            .catch((err, data) => {
                this.notifyErrorValidation(err)
            })
        },

        loadStates() {
            window.axios({
                method: 'get',
                url: `/api/referentiel/rating-states`,
                params: {sortBy: {"order": "asc"}}
            })
            .then(response => {
                this.states = response.data
            })
            .catch((err, data) => {
                this.notifyErrorValidation(err)
                if (callbackError) {
                    callbackError(err.response)
                }
            })
        },

        createState() {
            window.axios({
                method: 'post',
                url: `/api/referentiel/rating-states`,
                data: this.state
            })
            .then(response => {
                this.state.name = null;
                this.loadStates()
            })
            .catch((err, data) => {
                this.notifyErrorValidation(err)
                if (callbackError) {
                    callbackError(err.response)
                }
            })
        },
        updateState(state) {
            window.axios({
                method: 'patch',
                url: `/api/referentiel/rating-states/${state.id}`,
                data: state
            })
            .then(response => {
                this.state.name = null;
                this.loadStates()
                this.stateEditing = null;
            })
            .catch((err, data) => {
                this.notifyErrorValidation(err)
                if (callbackError) {
                    callbackError(err.response)
                }
            })
        },
        deleteState(state) {
            this.$swal({
                title: `Êtes-vous sûr de vouloir supprimer cet élément ?`,
                text: '',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Annuler'
            }).then(async result => {
                if (result.value) {
                    window.axios({
                        method: 'delete',
                        url: `/api/referentiel/rating-states/${state.id}`,
                    })
                    .then(response => {
                        this.loadStates()
                    })
                    .catch((err, data) => {
                        this.notifyErrorValidation(err)
                        if (callbackError) {
                            callbackError(err.response)
                        }
                    })
                }
            });
        }
    }
};
</script>

<style scoped>

/* TODO - lignes transposées dans le app.scss */
.drag-icon_ {
    width: 15px;
    height: 26px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGUlEQVQI12NgAIJz507/Z4AxVqxYAuEgAwD26QnAz+WLlgAAAABJRU5ErkJggg==);
    cursor: grab;
}
.dragging_ {
    background-color: #f3f3f3;
}


</style>
