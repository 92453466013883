<template>
    <div>
<!--        <div class="mt-1" v-for="(message, index) in messages" :key="index">
            <vs-alert
                v-if="isActive[index]"
                :key="'alert_'+index"
                closable
                close-icon="close"
                :color="message.type"
                :title="message.title"
                @click="hide(message, index)"
            ><span v-html="message.content"></span></vs-alert>
        </div>-->

        <div class="mt-1" v-for="(message, index) in messages" :key="index">
            <div v-if="isActive[index]" :class="'con-vs-alert con-vs-alert-'+message.type" style="height: 100%">
                <div :class="'con-x vs-alert--close'" @click="hide(message, index)">
                    <i class="vs-icon notranslate icon-scale material-icons null">close</i>
                </div>
                <h4 :class="'titlex vs-alert--title'">{{ message.title }}</h4>
                <div :class="'vs-alert'">
                    <span v-html="message.content"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Message",
    data() {
        return {
            isActiveArray: [],
        }
    },

    props: {
        messages: {
            default: () => [],
            type: Array
        },
    },

    computed: {
        isActive() {
            let activeArray = []
            this.isActiveArray.forEach((value, index) => {
                activeArray[index] = value
            })

            return activeArray
        }
    },

    created() {
        this.messages.forEach((message, index) => {
            this.isActiveArray[index] = localStorage.getItem('message_' + index + '_user_' + this.user.id) !== 'closed';
        })
    },

    methods: {
        hide(message, index) {
            localStorage.setItem('message_' + index + '_user_' + this.user.id, 'closed')
            this.$set(this.isActiveArray, index, false)
        }
    }
}
</script>

<style scoped lang="scss">

</style>