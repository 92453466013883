<template>
    <div>
        <vs-row>
            <vs-col vs-align="left" vs-w="6">
                <vs-button
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-plus"
                    @click="showFormCreate"
                >
                    Ajouter un questionnaire
                </vs-button>

                <DialogPrompt
                    :active.sync="showPopup"
                    :title="getDataPrompt.title"
                    cancel-text="Annuler"
                    accept-text="Enregistrer"
                    :button-accept="getDataPrompt.btnAccept"
                    :is-valid="!formError"
                    @cancel="resetResource"
                    @close="resetResource"
                    @accept="saveResource"
                >
                    <vs-row>
                        <vs-col>
                            <vs-input
                                v-model="resource.name"
                                name="name"
                                class="w-full"
                                label="Nom du questionnaire"
                                required
                            />
                        </vs-col>
                    </vs-row>

                    <!-- <hr class="mt-5 mb-5"> -->

                    <vs-row class="mt-10 mb-3">
                        <vs-col>
                            <h5>Questions</h5>
                        </vs-col>
                    </vs-row>

                    <vs-row>
                        <vs-col vs-w="12">
                            <p>Ajouter une question :</p>
                            <vx-input-group>
                                <vs-input
                                    v-model="tempQuestion"
                                    name="textZone"
                                    class="w-full"
                                    placeholder="Nom de la question"
                                    required
                                />

                                <template slot="append">
                                    <div class="append-text btn-addon">
                                        <vs-button
                                            id="addTextZone"
                                            icon-pack="feather"
                                            icon="icon-plus"
                                            @click.prevent="addQuestion"
                                        >
                                            Ajouter
                                        </vs-button>
                                    </div>
                                </template>
                            </vx-input-group>
                        </vs-col>
                    </vs-row>

                    <vs-row>
                        <vs-col>
                            <vs-table
                                v-if="resource.questions.length > 0"
                                max-items="10"
                                :data="orderedResourceQuestions"
                                no-data-text="Aucune donnée à afficher"
                                style="width:100%"
                            >
                                <template slot="thead">
                                    <vs-th style="width: 15px"></vs-th>
                                    <vs-th>
                                        Ordre
                                    </vs-th>
                                    <vs-th>
                                        Nom
                                    </vs-th>
                                    <vs-th v-show="resource.questions.length > 1">
                                        Action
                                    </vs-th>
                                </template>

                                <template slot-scope="{ data }">
                                    <tr
                                        v-for="(item, i) in data"
                                        :key="i"
                                        :data="item"
                                        :class="!!dragging && dragging.id == item.id ? 'dragging' : 'waiting-drag'"
                                    >
                                        <vs-td>
                                            <div
                                                class="drag-icon"
                                                draggable="true"
                                                @dragstart="dragStart(item, $event)"
                                                @dragover="dragOver(item.order, $event)"
                                                @drop="drop(item.order, $event)"
                                            ></div>
                                        </vs-td>
                                        <vs-td :data="item.order">
                                            {{ item.order }}
                                        </vs-td>
                                        <vs-td>
                                            <div v-if="questionEditing === i">
                                                <vx-input-group>
                                                    <vs-input
                                                        v-model="item.label"
                                                        name="textZone"
                                                        class="w-full"
                                                        placeholder="Nom"
                                                        required
                                                    />

                                                    <template slot="append">
                                                        <div class="append-text btn-addon">
                                                            <vs-button
                                                                icon-pack="feather"
                                                                icon="icon-edit"
                                                                @click.prevent="updateResource()"
                                                            >
                                                                Enregistrer
                                                            </vs-button>
                                                        </div>
                                                    </template>
                                                </vx-input-group>
                                            </div>
                                            <div v-else>
                                                {{ item.label }}
                                            </div>
                                        </vs-td>
                                        <vs-td v-if="resource.questions.length > 1 && questionEditing != i">
                                            <vs-button
                                                v-tooltip="'Modifier'"
                                                color="primary"
                                                type="border"
                                                icon-pack="feather"
                                                icon="icon-edit"
                                                @click="editQuestion(i)"
                                            />

                                            <vs-button
                                                v-tooltip="'Supprimer'"
                                                color="primary"
                                                type="border"
                                                icon-pack="feather"
                                                icon="icon-trash"
                                                style="margin-left: 1rem;"
                                                @click="deleteQuestion(i)"
                                            />
                                        </vs-td>
                                    </tr>
                                </template>
                            </vs-table>
                        </vs-col>
                    </vs-row>
                </DialogPrompt>
            </vs-col>
        </vs-row>

        <vs-row class="mt-3">
            <vs-col vs-w="12">
                <vs-table
                    :sst="true"
                    :no-data-text="loading ? `Chargement de vos données...` : `Aucune donnée à afficher`"
                    style="width:100%"
                    :data="items.data"
                    :loading="items.loading"
                >
                    <template slot="thead">
                        <vs-th>
                            #
                        </vs-th>
                        <vs-th>
                            Nom
                        </vs-th>
                        <vs-th>
                            Nombre de zones
                        </vs-th>
                        <vs-th class="text-right pr-6">
                            Action
                        </vs-th>
                    </template>

                    <template>
                        <vs-tr v-for="(item, i) in items.data" :key="i">
                            <vs-td>
                                {{ item.id }}
                            </vs-td>
                            <vs-td>
                                {{ item.name }}
                            </vs-td>
                            <vs-td>
                                {{ item.questions.length }}
                            </vs-td>
                            <vs-td class="action">
                                <div class="action-wrapper">
                                    <vs-button
                                        v-tooltip="'Modifier'"
                                        color="primary"
                                        type="border"
                                        icon-pack="feather"
                                        icon="icon-edit"
                                        @click.stop="showFormUpdate(item)"
                                    />

                                    <vs-button
                                        v-tooltip="'Supprimer'"
                                        color="primary"
                                        type="border"
                                        icon-pack="feather"
                                        icon="icon-trash"
                                        @click.stop="removeResource(item)"
                                    />
                                </div>
                            </vs-td>
                        </vs-tr>
                    </template>

                </vs-table>
            </vs-col>
        </vs-row>
    </div>
</template>

<script>
export default {
    name: "RatingQuestionnaires",
    props: {
        api: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            log: console.log,
            resource: {
                id: null,
                name: null,
                questions: []
            },
            loading: false,
            showPopup: false,
            formType: "create",
            tempQuestion: null,
            dragging: null,
            questionEditing: null,
            orderChanged: false,
            prompt: {
                show: {
                    title: 'Consulter un questionnaire',
                    btnAccept: 'false'
                },
                create: {
                    title: 'Ajouter un questionnaire',
                    btnAccept: 'filled'
                },
                update: {
                    title: 'Modifier un questionnaire existant',
                    btnAccept: 'filled'
                }
            },
            items: {
                data: [],
                loading: false
            }
        }
    },
    watch: {
        loading(value) {
            if (value) {
                this.$vs.loading()
            } else {
                this.$vs.loading.close()
            }
        }
    },

    computed: {
        formError() {
            return !this.resource.name || this.resource.questions.length == 0;
        },
        getDataPrompt() {
            switch(this.formType) {
                case 'show':
                    return this.prompt.show
                    break;
                case 'create':
                    return this.prompt.create
                    break;
                case 'update':
                    return this.prompt.update
                    break;
                default:
                    return this.prompt.show
            }
        },
        orderedResourceQuestions() {
            return this.resource.questions.sort((a, b) => a.order < b.order ? -1 : 1)
        }
    },
    methods: {
        loadItems() {
            this.items.loading = true;

            window.axios({
                method: 'get',
                url: this.api,
                params: {sortBy: {"id": "asc"}}
            })
            .then(response => {
                this.items.data = response.data.map(questionnaire => {
                    
                    questionnaire.questions.map((question, i) => {
                        question.id = i + 1
                        return question
                    });

                    return questionnaire;
                });
                this.items.loading = false;
            })
            .catch((err, data) => {
                this.notifyErrorValidation(err)
                if (callbackError) {
                    callbackError(err.response)
                }
            })
        },
        showFormCreate() {
            this.resetResource();
            this.formType = "create";
            this.showPopup = true
        },

        showFormUpdate(resource) {
            this.setResource(resource);
            this.formType = "update";
            this.showPopup = true
        },

        saveResource() {
            let data = JSON.parse(JSON.stringify(this.resource));

            if (data.id) {
                this.apiUpdate(data, data.id,
                    (resp) => this.loadItems(),
                    (err)  => this.showPopup = true
                );
            } else {
                this.apiCreate(data,
                    (resp) => this.loadItems(),
                    (err)  => this.showPopup = true
                );
            }
        },

        removeResource(resource) {
            this.apiDelete(resource.id, () => this.loadItems());
        },

        setResource(data) {
            this.resource = JSON.parse(JSON.stringify(data));
        },

        resetResource() {
            this.resource = {
                id: null,
                name: null,
                questions: []
            }
        },

        addQuestion() {
            this.resource.questions.push({
                id: this.resource.questions.length + 1,
                order: this.resource.questions.length + 1,
                label: this.tempQuestion
            });
            this.tempQuestion = null;
        },

        editQuestion(indice) {
            this.questionEditing = indice
        },

        updateResource(indice, item) {
            this.questionEditing = null;
            this.saveResource();
        },

        deleteQuestion(indice) {
            this.resource.questions.splice(indice, 1);
        },

        dragStart(item, ev) {
            ev.dataTransfer.setData("Text", this.id);
            ev.dataTransfer.dropEffect = "move";
            this.dragging = item;

            this.resource.questions.forEach((item, i) => {
                item.order = i + 1;
            });
        },

        drop(to, ev) {
            ev.preventDefault();
        },

        dragOver(to, ev) {

            ev.preventDefault();

            // Modification de l'ordre en live
            const from = this.dragging.order;

            if (from != to) {
                this.resource.questions.forEach((item, i) => {
                    if (item.id == this.dragging.id) {
                        this.resource.questions[i].order = to;
                    } else if (from < to) {
                        if (item.order > from && item.order <= to) {
                            this.resource.questions[i].order--;
                        }
                    } else {
                        if (item.order < from && item.order >= to) {
                            this.resource.questions[i].order++;
                        }
                    }
                })

                this.orderChanged = true;
            }
        },

    },
    created() {
        this.loadItems();
    }

}
</script>