<template>
    <div>
        <vx-card>
            <div class="vx-row mt-2 mb-6" :api="api">
                <div class="vx-col w-full">
                    <h4>Paramétrer les exports</h4>
                </div>
            </div>

            <!-- Action -->
            <vs-row>
                <vs-col vs-align="left" vs-w="6">
                    <vs-button
                        id="btn-create"
                        color="primary"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-plus"
                        @click="createResource"
                    >
                        Ajouter un nouveau modèle
                    </vs-button>

                    <DialogPrompt
                        :active.sync="showPopup"
                        :title="getDataPrompt.title"
                        cancel-text="Fermer"
                        accept-text="Enregistrer"
                        :button-accept="getDataPrompt.btnAccept"
                        :is-valid="!formError"
                        @cancel="resetResource"
                        @accept="storeResource"
                        @close="resetResource"
                    >
                        <Form
                            v-model="resource"
                            :type="formType"
                            :types="types"
                            @error="setFormError"
                        />
                    </DialogPrompt>
                </vs-col>
                <vs-col vs-type="flex" vs-align="left" vs-w="6" />
            </vs-row>

            <!-- Liste carnet de compétence -->
            <vs-row class="mt-20 with-border">
                <vs-col>
                    <h4>Carnet de compétence</h4>
                    <List 
                        :export-items="getSkillBookList"
                        @update="updateResource($event)"
                        @delete="deleteResource($event)"
                    />
                </vs-col>
            </vs-row>

            <!-- Liste projet personnalisé -->
            <vs-row class="mt-20 with-border">
                <vs-col>
                    <h4>{{ projectNames.singular }}</h4>
                    <List 
                        :export-items="getRatingList"
                        @update="updateResource($event)"
                        @delete="deleteResource($event)"
                    />
                </vs-col>
            </vs-row>

            <vs-row class="mt-20 with-border">
                <vs-col>
                    <h4>Évaluation portail tiers</h4>
                    <List 
                        :export-items="getExternalList"
                        @update="updateResource($event)"
                        @delete="deleteResource($event)"
                    />
                </vs-col>
            </vs-row>

            <vs-row class="mt-20 with-border">
                <vs-col>
                    <h4>Auto-évaluation Portail</h4>
                    <List 
                        :export-items="getPersonalList"
                        @update="updateResource($event)"
                        @delete="deleteResource($event)"
                    />
                </vs-col>
            </vs-row>
        </vx-card>
    </div>
</template>

<script>
import Form from './Form.vue';
import List from './partials/List.vue';

export default {
    name: "Export",
    components: {Form, List},
    props: {
        api: {
            type: String,
            required: true
        },
        perpage: {
            type: String
        },
        types: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            log: console.log,
            currentPage: 1,
            loading: false,
            showPopup: false,
            resource: {},
            formError: true,
            formType: 'show',
            prompt: {
                show: {
                    title: 'Consulter un modèle',
                    btnAccept: 'false'
                },
                create: {
                    title: 'Ajouter un modèle',
                    btnAccept: 'filled'
                },
                update: {
                    title: 'Modifier un modèle',
                    btnAccept: 'filled'
                }
            }
        };
    },

    watch: {
        loading(value) {
            if (value) {
                this.$vs.loading();
            } else {
                this.$vs.loading.close();
            }
        }
    },

    computed: {
        getDataPrompt() {
            switch(this.formType) {
                case 'show':
                    return this.prompt.show
                case 'create':
                    return this.prompt.create
                case 'update':
                    return this.prompt.update
                default:
                    return this.prompt.show
            }
        },
        getSkillBookList() {
            return this.items.data.filter(i => i.type_id == 1);
        },
        getRatingList() {
            return this.items.data.filter(i => i.type_id == 2);
        },
        getPersonalList() {
            return this.items.data.filter(i => i.type_id == 4);
        },
        getExternalList() {
            return this.items.data.filter(i => i.type_id == 3);
        }
    },

    methods: {

        createResource() {
            this.resetResource();
            this.formType = "create";
            this.showPopup = true
        },

        updateResource(resource) {
            this.resetResource();
            this.setResource(resource);
            this.formType = "update";
            this.showPopup = true;
        },

        storeResource() {
            this.loading = true;
            let data = JSON.parse(JSON.stringify(this.resource));
            data.model = this.resource.model;
            data.visibility = data.visibility ? 1 : 0;

            if (data.id) {
                this.apiUpdate(
                    data,
                    data.id,
                    resp => this.loadItems(),
                    err => {
                        this.showPopup = true;
                        this.loading = false
                    },
                    {'Content-Type': 'multipart/form-data'}
                );
            } else {
                this.apiCreate(
                    data,
                    resp => this.loadItems(),
                    err => {
                        this.showPopup = true;
                        this.loading = false
                    },
                    {'Content-Type': 'multipart/form-data'}
                );
            }
        },

        deleteResource(resource) {
            this.apiDelete(resource.id, () => this.loadItems());
        },

        setResource(data) {
            this.resource = JSON.parse(JSON.stringify(data));
        },

        resetResource() {
            this.resource = {
                title: null,
                type_id: null,
                visibility: 1,
                model: null,
            };
        },

        setFormError(value) {
            this.formError = value;
        },
    },
    created() {
        this.apiParams.perpage = 999;
        this.apiParams.include = [];
        this.apiParams.sortBy = {};
        this.loadItems();
    }
};
</script>
<style>
    .with-border .vs-con-table {
        border: 3px solid #d9d9d9;
        border-radius: 0;
    }

    .with-border .vs-con-table .vs-con-tbody {
        border: none;

    }
</style>
