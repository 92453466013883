var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("JobsOccupationsResume", {
        attrs: {
          jobs: _vm.value.jobs,
          occupations: _vm.value.occupations,
          displayWishes: false
        }
      }),
      _vm._v(" "),
      !_vm.loading && !_vm.resource.occupations_skills
        ? _c("div", { staticClass: "not-data-table vs-table--not-data" }, [
            _vm._v("Il n'y a aucun savoir-faire à évaluer")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            {
              staticClass: "mt-8",
              attrs: {
                "vs-w": "4",
                "vs-type": "flex",
                "vs-justify": "flex-start",
                "vs-align": "center"
              }
            },
            [
              _c(
                "vs-checkbox",
                {
                  model: {
                    value: _vm.showDeletedOccSkills,
                    callback: function($$v) {
                      _vm.showDeletedOccSkills = $$v
                    },
                    expression: "showDeletedOccSkills"
                  }
                },
                [
                  _vm._v(
                    "\n                Affichage des savoir-faire supprimés\n            "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "vs-col",
            { staticClass: "mt-8", attrs: { "vs-w": "2" } },
            [
              _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value:
                        "Remplacer les données avec la dernière sauvegarde",
                      expression:
                        "`Remplacer les données avec la dernière sauvegarde`"
                    }
                  ],
                  staticClass: "ml-3",
                  attrs: {
                    type: "border",
                    "icon-pack": "feather",
                    icon: "icon-refresh-cw"
                  },
                  on: { click: _vm.resetFromLastRatingClickHandle }
                },
                [_vm._v("\n                Dernière sauvegarde\n            ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "vs-col",
            { attrs: { "vs-w": "6" } },
            [
              _c(
                "vs-row",
                { staticClass: "mt-8" },
                [
                  _c(
                    "vs-col",
                    { attrs: { "vs-w": "6" } },
                    [
                      _c(
                        "vs-checkbox",
                        {
                          attrs: { disabled: !_vm.hasPersonals },
                          model: {
                            value: _vm.comparePortalPersonal,
                            callback: function($$v) {
                              _vm.comparePortalPersonal = $$v
                            },
                            expression: "comparePortalPersonal"
                          }
                        },
                        [
                          _vm._v(
                            "\n                        Auto-évaluation Portail\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "vs-col",
                    { attrs: { "vs-w": "6" } },
                    [
                      _c(
                        "vs-checkbox",
                        {
                          attrs: { disabled: !_vm.hasExternals },
                          model: {
                            value: _vm.comparePortalExternal,
                            callback: function($$v) {
                              _vm.comparePortalExternal = $$v
                            },
                            expression: "comparePortalExternal"
                          }
                        },
                        [
                          _vm._v(
                            "\n                        Évaluation(s) Portail\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.resource.occupations_skills || _vm.loading
        ? _c("Rating-Manager", {
            attrs: {
              name: "occupation-skills",
              "type-form": _vm.typeForm,
              "show-deleted": _vm.showDeletedOccSkills,
              col1: {
                label: "Savoir-faire",
                value: "title",
                title: "description"
              },
              col2: {
                label: "Poste / Métier",
                value: "job_name",
                title: "type"
              },
              col3: { label: "Service / Filière", value: "service_name" },
              loading: _vm.loading,
              "skill-review": _vm.skillReview
            },
            model: {
              value: _vm.resource.occupations_skills,
              callback: function($$v) {
                _vm.$set(_vm.resource, "occupations_skills", $$v)
              },
              expression: "resource.occupations_skills"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }