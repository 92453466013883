var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.messages, function(message, index) {
      return _c("div", { key: index, staticClass: "mt-1" }, [
        _vm.isActive[index]
          ? _c(
              "div",
              {
                class: "con-vs-alert con-vs-alert-" + message.type,
                staticStyle: { height: "100%" }
              },
              [
                _c(
                  "div",
                  {
                    class: "con-x vs-alert--close",
                    on: {
                      click: function($event) {
                        return _vm.hide(message, index)
                      }
                    }
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass:
                          "vs-icon notranslate icon-scale material-icons null"
                      },
                      [_vm._v("close")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("h4", { class: "titlex vs-alert--title" }, [
                  _vm._v(_vm._s(message.title))
                ]),
                _vm._v(" "),
                _c("div", { class: "vs-alert" }, [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(message.content) }
                  })
                ])
              ]
            )
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }