var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            { attrs: { "vs-align": "left", "vs-w": "6" } },
            [
              _c(
                "vs-button",
                {
                  attrs: {
                    id: "saveResource",
                    color: "primary",
                    type: "filled",
                    "icon-pack": "feather",
                    icon: "icon-plus"
                  },
                  on: { click: _vm.showFormCreate }
                },
                [
                  _vm._v(
                    "\n                Ajouter une nouvelle personne\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "DialogPrompt",
                {
                  attrs: {
                    active: _vm.showPopup,
                    title: "Ajouter une personne",
                    "cancel-text": "Annuler",
                    "accept-text": "Enregistrer",
                    "is-valid": !_vm.formError
                  },
                  on: {
                    "update:active": function($event) {
                      _vm.showPopup = $event
                    },
                    cancel: _vm.resetResource,
                    close: _vm.resetResource,
                    accept: _vm.saveResource
                  }
                },
                [
                  _c("EmployeeForm", {
                    attrs: { formType: "create" },
                    on: { error: _vm.setFormError },
                    model: {
                      value: _vm.resource,
                      callback: function($$v) {
                        _vm.resource = $$v
                      },
                      expression: "resource"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("vs-col", {
            attrs: { "vs-type": "flex", "vs-align": "left", "vs-w": "6" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vs-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            sst: true,
            search: "",
            "no-data-text": _vm.loading
              ? "Chargement de vos données..."
              : "Aucune donnée à afficher",
            data: _vm.itemsData
          },
          on: { search: _vm.eventSearchItems, sort: _vm.eventSortByCol }
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c(
                "vs-th",
                [
                  _c(
                    "Sort",
                    {
                      attrs: { name: "last_name" },
                      on: { sort: _vm.eventSortByCol }
                    },
                    [_vm._v("Nom")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "vs-th",
                [
                  _c(
                    "Sort",
                    {
                      attrs: { name: "first_name" },
                      on: { sort: _vm.eventSortByCol }
                    },
                    [_vm._v("Prénom")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "vs-th",
                [
                  _c(
                    "Sort",
                    {
                      attrs: { name: "services.establishment,name" },
                      on: { sort: _vm.eventSortByCol }
                    },
                    [_vm._v("Établissements")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "vs-th",
                [
                  _c(
                    "Sort",
                    {
                      attrs: { name: "services,title" },
                      on: { sort: _vm.eventSortByCol }
                    },
                    [_vm._v("Services")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("vs-th", [_vm._v("Postes occupés")]),
              _vm._v(" "),
              _c("vs-th", [_vm._v("Métiers occupés")]),
              _vm._v(" "),
              _vm.canSeeSwitchMobility
                ? _c("vs-th", [_vm._v("Compte portail")])
                : _vm._e(),
              _vm._v(" "),
              _c("vs-th")
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.employees, function(item, i) {
            return _c(
              "vs-tr",
              { key: i },
              [
                _c("vs-td", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "/rh/gestion/personnes/" + item.id + "/bilans"
                      }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.get(item, ":last_name")) +
                          "\n                    "
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("vs-td", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "/rh/gestion/personnes/" + item.id + "/bilans"
                      }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.get(item, ":first_name")) +
                          "\n                    "
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("vs-td", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm
                          .arrayUnique(
                            _vm
                              .get(item, ":active_establishments")
                              .map(function(s) {
                                return s.name
                              })
                          )
                          .join(", ")
                      ) +
                      "\n                    "
                  )
                ]),
                _vm._v(" "),
                _c("vs-td", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm
                          .arrayUnique(
                            _vm.get(item, ":active_services").map(function(s) {
                              return s.title
                            })
                          )
                          .join(", ")
                      ) +
                      "\n                    "
                  )
                ]),
                _vm._v(" "),
                _c("vs-td", [
                  _vm.expressEdit.showJobCell != i
                    ? _c(
                        "div",
                        [
                          _vm._l(_vm.get(item, ":current_jobs"), function(
                            el,
                            index
                          ) {
                            return _c("vs-chip", { key: index }, [
                              _c("b", [_vm._v(_vm._s(el.title))])
                            ])
                          }),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "fas fa-pencil cell-edit opaver",
                            attrs: { title: "Modifier les postes" },
                            on: {
                              click: function($event) {
                                return _vm.editJobCellClickHandle(i)
                              }
                            }
                          })
                        ],
                        2
                      )
                    : _c("div", [
                        _c("form", [
                          _c(
                            "div",
                            { staticClass: "edit-form" },
                            [
                              _c(
                                "Multiselect",
                                {
                                  attrs: {
                                    options: _vm.orderedJobs,
                                    multiple: true,
                                    "group-values": "jobs",
                                    "group-label": "establishmentService",
                                    "group-select": true,
                                    placeholder: "Recherche ...",
                                    "track-by": "id",
                                    label: "title",
                                    "select-label": "",
                                    "select-group-label": "",
                                    "selected-label": "",
                                    "deselect-label": "",
                                    "deselect-group-label": "",
                                    loading: _vm.jobs.loading
                                  },
                                  model: {
                                    value: _vm.expressEdit.jobValue,
                                    callback: function($$v) {
                                      _vm.$set(_vm.expressEdit, "jobValue", $$v)
                                    },
                                    expression: "expressEdit.jobValue"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "noResult" },
                                      slot: "noResult"
                                    },
                                    [_vm._v("Aucun résultat.")]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "edit-action text-right mt-2" },
                            [
                              _c(
                                "vs-button",
                                {
                                  staticClass: "mb-1",
                                  attrs: { color: "dark", type: "border" },
                                  on: {
                                    click: function($event) {
                                      return _vm.resetEditingLine(i)
                                    }
                                  }
                                },
                                [
                                  _c("i", { staticClass: "fa fa-times mr-1" }),
                                  _vm._v(
                                    "\n                                    Annuler\n                                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "vs-button",
                                {
                                  staticClass: "mb-1",
                                  attrs: {
                                    color: "primary",
                                    disabled: _vm.expressEdit.saving
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.savePerimeterLine(i)
                                    }
                                  }
                                },
                                [
                                  !_vm.expressEdit.saving
                                    ? _c("i", {
                                        staticClass: "far fa-save mr-1"
                                      })
                                    : _c("i", {
                                        staticClass: "fa fa-spinner rotate mr-1"
                                      }),
                                  _vm._v(
                                    "\n                                    Enregistrer\n                                "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                ]),
                _vm._v(" "),
                _c("vs-td", [
                  _vm.expressEdit.showOccupationCell != i
                    ? _c(
                        "div",
                        [
                          _vm._l(
                            _vm.get(item, ":current_occupations"),
                            function(el, index) {
                              return _c("vs-chip", { key: index }, [
                                _c("b", [_vm._v(_vm._s(el.title))])
                              ])
                            }
                          ),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "fas fa-pencil cell-edit opaver",
                            attrs: { title: "Modifier les métiers" },
                            on: {
                              click: function($event) {
                                return _vm.editOccupationCellClickHandle(i)
                              }
                            }
                          })
                        ],
                        2
                      )
                    : _c("div", [
                        _c("form", [
                          _c(
                            "div",
                            { staticClass: "edit-form" },
                            [
                              _c(
                                "Multiselect",
                                {
                                  attrs: {
                                    options: _vm.orderedOccupations,
                                    multiple: true,
                                    "group-values": "occupations",
                                    "group-label": "type",
                                    "group-select": true,
                                    placeholder: "Recherche ...",
                                    "track-by": "id",
                                    label: "title",
                                    "select-label": "",
                                    "select-group-label": "",
                                    "selected-label": "",
                                    "deselect-label": "",
                                    "deselect-group-label": "",
                                    loading: _vm.occupations.loading
                                  },
                                  model: {
                                    value: _vm.expressEdit.occupationValue,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.expressEdit,
                                        "occupationValue",
                                        $$v
                                      )
                                    },
                                    expression: "expressEdit.occupationValue"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "noResult" },
                                      slot: "noResult"
                                    },
                                    [_vm._v("Aucun résultat.")]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "edit-action text-right mt-2" },
                            [
                              _c(
                                "vs-button",
                                {
                                  staticClass: "mb-1",
                                  attrs: { color: "dark", type: "border" },
                                  on: {
                                    click: function($event) {
                                      return _vm.resetEditingLine(i)
                                    }
                                  }
                                },
                                [
                                  _c("i", { staticClass: "fa fa-times mr-1" }),
                                  _vm._v(
                                    "\n                                    Annuler\n                                "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "vs-button",
                                {
                                  staticClass: "mb-1",
                                  attrs: {
                                    color: "primary",
                                    disabled: _vm.expressEdit.saving
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.savePerimeterLine(i)
                                    }
                                  }
                                },
                                [
                                  !_vm.expressEdit.saving
                                    ? _c("i", {
                                        staticClass: "far fa-save mr-1"
                                      })
                                    : _c("i", {
                                        staticClass: "fa fa-spinner rotate mr-1"
                                      }),
                                  _vm._v(
                                    "\n                                    Enregistrer\n                                "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                ]),
                _vm._v(" "),
                _c("vs-td", [
                  _vm.planAllow("auto-eval-falc-portail")
                    ? _c(
                        "div",
                        [
                          _vm.canSeeSwitchMobility
                            ? _c("vs-switch", {
                                attrs: {
                                  disabled: !_vm.canMulti(
                                    "update",
                                    "employees",
                                    item.active_services.map(function(s) {
                                      return s.id
                                    })
                                  )
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.eventSwitchMobility(i)
                                  }
                                },
                                model: {
                                  value: _vm.items.data[i].mobility,
                                  callback: function($$v) {
                                    _vm.$set(_vm.items.data[i], "mobility", $$v)
                                  },
                                  expression: "items.data[i].mobility"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : !_vm.shouldHideOptions()
                    ? _c(
                        "div",
                        {
                          staticStyle: { position: "relative", float: "left" },
                          attrs: {
                            title:
                              "Votre formule n'inclut pas cette fonctionnalité"
                          }
                        },
                        [
                          _c("vs-switch", {
                            attrs: { disabled: true },
                            model: {
                              value: _vm.items.data[i].mobility,
                              callback: function($$v) {
                                _vm.$set(_vm.items.data[i], "mobility", $$v)
                              },
                              expression: "items.data[i].mobility"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "vs-icon",
                            {
                              staticClass: "button-bullet",
                              attrs: { bg: "#FF9B3A", round: "" }
                            },
                            [
                              _c("CrownIcon", {
                                staticStyle: {
                                  width: "16px",
                                  height: "16px",
                                  padding: "3px"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("vs-td", { staticClass: "action" }, [
                  _c(
                    "div",
                    { staticClass: "action-wrapper" },
                    [
                      _vm.can("read", "employee_ratings")
                        ? _c("vs-button", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: "Evaluer",
                                expression: "'Evaluer'"
                              }
                            ],
                            attrs: {
                              id: "ratingEmployee_" + i,
                              href:
                                "/rh/gestion/personnes/" + item.id + "/bilans",
                              color: "primary",
                              type: "border",
                              "icon-pack": "feather",
                              icon: "icon-check-square"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("vs-button", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: "Compléter le parcours",
                            expression: "'Compléter le parcours'"
                          }
                        ],
                        attrs: {
                          id: "modifyEmployee_" + i,
                          href:
                            "/rh/gestion/personnes/" + item.id + "/parcours",
                          color: "primary",
                          type: "border",
                          "icon-pack": "feather",
                          icon: "icon-edit"
                        }
                      }),
                      _vm._v(" "),
                      _vm.isASuperAdmin()
                        ? _c("vs-button", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: "Supprimer",
                                expression: "'Supprimer'"
                              }
                            ],
                            attrs: {
                              id: "deleteEmployee_" + i,
                              color: "primary",
                              type: "border",
                              "icon-pack": "feather",
                              icon: "icon-trash"
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.removeResource(item)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ],
              1
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "vs-row",
        [
          _vm.items.total > 0
            ? _c(
                "vs-col",
                { staticClass: "mt-4" },
                [
                  _c("vs-pagination", {
                    attrs: {
                      total: Math.ceil(_vm.items.total / _vm.apiParams.perpage)
                    },
                    model: {
                      value: _vm.currentPage,
                      callback: function($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }